#email-sent-view{
    height: 100vh;
    background-color: #f2f4f8;
}
.email-sent-card {
    background: #fff;
    min-height: 60vh;
    border: 1px solid var(--primary);
}
.email-sent-card img{
    width: 5rem;
}
.email-sent-card h3 {
    font-size: 1.75rem;
    font-weight: bold;
}
.email-sent-card p {
    margin: 0;
    color: #212121;
    text-align: center;
    padding: 0 25%;
}
.email-sent-card .mail {
    color: var(--primary);
    font-weight: 600;
    margin: 0 .25rem;
}