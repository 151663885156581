.input-range__track {
    background: rgba(255,255,255,0.2);
    height: 0.75rem;
}
.input-range__track--active{ background: #fff}

.input-range__label--value {top: -3rem;}

.input-range__label--min .input-range__label-container,
.input-range__label--max .input-range__label-container 
{ left: 0;}

.input-range__label--value .input-range__label-container {
    border: 1px solid #fff;
    border-radius: 5px;
    padding: .125rem .5rem;
    background: #fff;
    color: #212121;
    font-weight: 600;
    position: relative;
}
.input-range__label--value .input-range__label-container::after {
    content: '';
    height: 8px;
    width: 8px;
    background: #fff;
    position: absolute;
    right: 50%;
    bottom: 0;
    transform: translate(50%,50%) rotate(45deg) ;
}
.input-range__label {
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
}
.input-range__slider { 
    background: #fff;
    border: solid 1px rgba(151, 151, 151, 0.3);
    height: 1.25rem;
    width: 1.25rem;
    margin-top: -1rem; 
}

.primary .input-range__track {
    background: rgba(151, 151, 151, 0.3);
}
.primary .input-range__track--active { 
    background: var(--primary); 
}
.primary .input-range__slider{
    border: solid 2px var(--primary);
}
.primary .input-range__label {
    color: #221122;
}
.primary .input-range__label--value .input-range__label-container{
    background: #000;
    color: #fff;
    font-weight: normal;
}
.primary .input-range__label--value .input-range__label-container::after {
    background: #000;
}
