#community-guidelines{
    margin-top: 3rem;
}
#community-guidelines .title{
    font-size: 1.8rem;
    font-weight: bold;
    color: #212121;
}
#community-guidelines .guidelines-info{
    font-size: 1rem;
    font-weight: normal;
    color: #716d6d;
    margin-top: 2rem;
}
#community-guidelines h1{
    font-size: 1rem;
    font-weight: bold;
    color: #212121;
}
#community-guidelines p{
    font-size: 1rem;
    font-weight: normal;
    color: #716d6d;
}
#community-guidelines li{
    font-size: 1rem;
    font-weight: normal;
    color: #716d6d;
}
#community-guidelines .footer{
    margin-top: 3rem;
}
.community-list li {
    list-style: none;
}
.community-list li a {
    color: #9c9c9c !important;
}