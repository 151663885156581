#progresscheck-welcome-page h2 {
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1.5;
}
#progresscheck-welcome-page h1 {
  font-weight: 900;
  font-size: 3.75rem;
  margin-bottom: 6.825rem;
  text-align: center;
}
#progresscheck-welcome-page .welcome-combined {
  display: block;
  -webkit-text-fill-color: transparent; /*Will override color (regardless of order)*/
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #fff;
}
#progresscheck-welcome-page .purpose {
  border-radius: 6px;
  border: solid 1px #ffffff;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 1rem;
}
#progresscheck-welcome-page .purpose p {
  margin: 0;
  text-align: left;
}

.welcome-container {
  height: 100vh;
  overflow-y: auto;
}
.welcome-container::-webkit-scrollbar {
  display: none;
  width: 0 !important;
}
@media (max-width: 536px) {
  #progresscheck-welcome-page h1 {
    font-size: 2.2rem;
    margin-bottom: 4.825rem;
  }
  #progresscheck-content h3 {
    font-size: 1.4rem;
  }
}
