#assessment-result {
    background-color: #f2f4f8;
    min-height: 100vh;
}

.result-overview {
    min-height: 20rem;
    border-bottom: 1px solid #eaedf3;
}
.result-overview-total {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 2.325rem;
    height: 2.325rem;
    border-radius: 50%;
    text-align: center;
    padding: 0.5rem;
    font-size: 0.825rem;
}
/* .result-overview-header,
.result-overview-body { 
    border-bottom: 1px solid #eaedf3
}
.result-overview-header h6{
    font-weight: bold;
    margin: 0
}

.result-overview-footer ul li {
    list-style: none;
    color: #212121;
    text-transform: capitalize;
}

.result-overview-footer ul li .legend{
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    margin-right: 0.75rem;
    margin-bottom: 1px;
} */

/* RESULT */

.assessment-result {
    background-color: #fff;
    border-radius: 0.25rem;
}

.assessment-result ul li {
    list-style: none;
    color:#9c9c9c;
    text-transform: capitalize;
    padding: 0.625rem 1rem;
}
.assessment-result ul li > span{ float: right; }
.assessment-result ul li:hover {
    background-color: rgba(0, 0, 0, 0.02);
    cursor: pointer;
}

.assessment-result ul li:last-child {
    background-color: #eaedf3;
    color: #212121;
    font-weight: 600;
    
}

.assessment-result .dropdown .dropdown-toggle {
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: none;
    background: #fff;
    color: unset;
    border-bottom: 1px solid #eaedf3;
    text-transform: capitalize;
    padding: 0.5rem 0.75rem;
}

.assessment-result .dropdown .dropdown-toggle:active {
    color: unset;
    background-color: #fff;
    border-color: #eaedf3;
}

.assessment-result .dropdown .dropdown-menu {
    right: 0;
    border-radius: 0;
    border-color: #eaedf3;
}

/* DESCRIPTION CARD */

#assessment-result .wholeness-description-card,
#assessment-result .helpful-resouces
{
    background: #fff;
    border-radius: 0.25rem;
    padding: 1rem;
}
#assessment-result .wholeness-description-card h1 {
    font-size: 2rem;
    color: #212121;
    font-weight: bold;
    text-align: left;
}
#assessment-result .wholeness-description-card p {
    color: #9c9c9c;
    line-height: 1.5;
}

/* @media (min-width: 1600px) {

    #assessment-result .container {
        max-width: 1300px;
    }
} */

.assessment-status-chart {
    background-image: linear-gradient(to right, #c5c5c5, #e9e9e9);
    height: 1.25rem;
    position: relative;
}
.assessment-status-chart .asessment-score {
    position: absolute;
    top: 0;
    transform: translateX(-50%) translateY(-115%);
    left: 56%;
    color: #fff;
    font-weight: 600;
    background-color: var(--primary);
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    z-index: 10;
    text-align: center;
    line-height: 1.9;
    /* margin-bottom: 0.25rem; */
}
.assessment-status-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    height: 100%;
}
.assessment-status-chart .assessment-status {
    width: 10%;
    height: 100%;
}
.assessment-status-chart .assessment-status:not(:last-child) {
    border-right: 1px solid #fff;
}


.assessment-status-chart .assessment-status .status-number {
    position: absolute;
    top: 0;
    transform: translateY(-100%);
}
.assessment-status-chart .assessment-status .status-progress {
    position: absolute;
    height: 100%;
    /* width: 100%; */
    background-color: var(--primary);
}
.assessment-status-chart .assessment-status .status-text {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
}

.assessment-result-tab nav {
    padding-top: 1rem;
}

@media (max-width: 576px) {
    .assessment-status-chart .assessment-status .status-text{
        display: none;
    }
}