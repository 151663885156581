.article-card {
    border-color: #eaedf3;
    cursor: pointer;
    transition: .7s;
    height: 317px;
    overflow: hidden;
}
.article-card h6.article-heading {
    margin-bottom: .5rem;
    color: #212121;
    font-weight: 600;
    line-height: 1.5;
     /* autoprefixer: off */
   -webkit-box-orient: vertical;
   display: block;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   overflow: hidden;
   text-overflow: ellipsis;
   word-break: break-word;
}
.article-card p.article-content {
    margin-bottom: .5rem;
    color: #9c9c9c;
    font-size: 0.75rem;
       /* autoprefixer: off */
   -webkit-box-orient: vertical;
   display: block;
   display: -webkit-box;
   -webkit-line-clamp: 3;
   overflow: hidden;
   text-overflow: ellipsis
}

.article-card .article-image {
    height: 9rem;
    transition: 0.5s;
}
.article-card .article-image > img {
    height: 100%;
    object-fit: cover;
    position: relative;
}

.article-card:hover{
    transform: translateY(0);
    /* box-shadow: 0 4px 8px 0 hsla(212, 83%, 7%, 0.11); */
}


.article-card:hover .article-image {
    background-image: linear-gradient(to bottom, hsl(212, 83%, 7%), hsla(212, 83%, 7%, 0));
}


.article-card:hover .article-image > img {
    z-index: -10;
}

.article-card .article-footer {
    margin: 0;
    font-size: .75rem;
    line-height: 1.5;
    color: #031121;
}
.article-card .article-footer span:nth-child(2) {
    color: #9c9c9c;
}
.article-card .article-footer > span:nth-child(2)::before{
    content: '\2022';
    margin: 0 4px;
}
.article-card .type-icon{
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 100;
}

/* CONTENT CARD SKELTON */
.content-card-skeleton {
    border-color: #eaedf3;
    transition: .7s;
    height: 300px;
    overflow: hidden;
}
.img-placehoder {
    height: 9rem;
}
.img-placehoder.shine {
    background-size: 800px 9rem;
}