input.gt-form-control {
    font-size: 1rem;
    color: #212121;
    font-weight: normal;
    height: 3em;
    border-radius: 3px;
    background-color: #fff !important;
    border: solid 1px #dedede;
    padding: 0.375rem 0.75rem;
    box-shadow: none;
    outline: none;
}
.input-icon {
    position: absolute;
    top: 50%;
    left: 1rem;
    z-index: 10;
    color: #9c9c9c;
    font-size: 1rem;
    transform: translateY(-50%);
}
.input-icon + input.gt-form-control {
    padding-left: 2.25rem;
}
input.gt-form-control:focus,
input.gt-form-control:hover{
    border-color: var(--primary);
    color: #212121;
}
input.gt-form-control:invalid {
    border-color: 'red'
}
input[type='checkbox'] {
    -webkit-appearance: none;
    border: 1px solid #dedede;
    margin-right: 0.625em; 
    height: 1.1em;
    width: 1.1em;
	border-radius: 3px;
	display: inline-block;
    position: relative;
    cursor: pointer;
    padding: 0;
}
input[type='checkbox']:focus {
    outline: none;
}
input[type='checkbox']:hover,
input[type='checkbox']:checked
{
    border-color:var(--primary);
    background-color: var(--primary);
}

input[type='checkbox']:checked::after {
    content: '';
    font-size: 1.1em;
    line-height: 1;
    position: absolute;
    display: inline-block;
    background-color: var(--primary);
    height: 100%;
    width: 100%;
    top: 0;
    background-image: url('/icons/tick-white.png');
    background-repeat: no-repeat;
    background-size: 0.625rem;
    background-position: center;
    /* display: flex;
    align-items: center;
    justify-content: center; */
	/* left: 2px; */
	color: var(--primary);
}
.gt-form-control:focus{
    color: #212121;
    box-shadow: none;
    border: solid 1px #dedede;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
    -webkit-box-shadow:0 0 0 50px white inset; 
    -webkit-text-fill-color: #000; 
}
input.gt-form-control.invalid {
    box-shadow: 0 0 14px 0 rgba(255, 0, 0, 0.2) !important;
    border-color: #ff0000 !important;
}
.input-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1em;
}
input.gt-form-control.material {
    background-color: transparent !important;
}