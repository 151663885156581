#terms-and-service .termsofservice{
    font-size: 1.8rem;
    font-weight: bold;
    color: #212121;
    margin-top: 3rem;
}
#terms-and-service .introduction {
    font-size: 1rem;
    font-weight: bold;
    color: #212121;
    margin-top: 2rem;
  }
  #terms-and-service .terms {
    font-size: 1rem;
    color: #716d6d;
  }
  #terms-and-service .terms a{
    border-bottom: 5px;
  }
  #terms-and-service .list-item{
      margin-top: 1rem;
      font-weight: normal;
      color: #212121;
  }
  #terms-and-service .list-item-active{
    margin-top: 1rem;
    font-weight: bold;
    color: #212121;
  }
  #terms-and-service .list-item a{
    color: #212121;
  }
  #terms-and-service .terms-list{
   position: sticky;
   top: 2rem;
  }
  #terms-and-service .terms a{
   color: #4392f1;
   /* border-bottom: 1px solid #4392f1; */
 }
 #terms-and-service li{
    padding: 0;
  }