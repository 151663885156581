.line-height {
  line-height: 1.5;
}

.star {
  font-family: "icon-star";
  font-size: 0.5rem;
}

.author-name {
  font-size: 0.75rem;
}
.article-icon {
  width: 1.32rem;
  height: 1.32rem;
  position: absolute;
  top: 2%;
  left: 2%;
}

.audio-icon {
  width: 1.32rem;
  height: 1.32rem;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 100;
}

.premium {
  position: absolute;
  top: 0%;
  right: 0%;
  z-index: 100;
}

.feed-profile {
  position: absolute;
  height: fit-content;
  object-fit: cover;
}

.feed-description {
  line-height: 1.5;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.feed-container {
  /* border-bottom: solid 1px #eaedf3; */
  background-color: #ffffff;
}

.feed-card-1 .article-card {
  height: 34rem;
}
.feed-card-1 .article-card .article-image{
  height: 25rem;
}

.feed-card-2 .article-card {
  height: 23rem;
}
.feed-card-2 .article-card .article-image{
  height: 12rem;
}


.content-card {
  height: 10rem;
  cursor: pointer;
}
.content-card .content-card-image {
  height: 100%;
  width: 10rem;
  min-width: 10rem;
  object-fit: cover;
  position: relative;
}
/* .content-card:hover .content-card-img-container {
  background-image: linear-gradient(to bottom, hsl(212, 83%, 7%), hsla(212, 83%, 7%, 0));
}
.content-card:hover .content-card-image {
  z-index: -10;
} */


@media (max-width: 768px) {
  .feed-card-1 .article-card {
    height: 29rem;
  }
  .feed-card-1 .article-card .article-image{
    height: 20rem;
  }
  .feed-card-2 .article-card {
    height: 20rem;
  }
  .feed-card-2 .article-card .article-image{
    height: 9rem;
  }

  .content-card {
    height: 8rem;
  }
  .content-card .content-card-image {
    width: 8rem;
    min-width: 8rem;
  }
}

@media (max-width: 567px) {
  .feed-card-1 .article-card {
    height: 20rem;
  }
  .feed-card-1 .article-card .article-image{
    height: 9rem;
  }
}

.full-width-feed-img {
  height: 25rem;
  cursor: pointer;
}

.full-width-feed-card-image {
  height: 17rem;
  cursor: pointer;
}
.full-width-feed-card-image img[alt="feeds"] {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.feed-type-two-img {
  height: 11rem;
  cursor: pointer;
}

@media (max-width: 536px) {
  .full-width-feed-img {
    height: 100%;
    cursor: pointer;
  }
  .full-width-feed-card-image {
    height: 100%;
    cursor: pointer;
  }
}

.top-right {
  position: absolute;
  right: 0;
  top: 0;
}