/* .navbar{
   height: 3.875rem !important;
   border-bottom: 1px solid #eaedf3;
} */
.navbar .triangle{
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    border-top: 4px solid  #031121;
    border-radius:0.3px; 
}
.navbar .publish{
    font-family: Source Sans Pro;
    font-size:  0.875rem;
    font-weight: bold;
    color: #031121;
}
.navbar img{
    cursor: pointer;
}
.navbar .searchBox{
    border: 1px solid #eaedf3;
    border-radius:3px; 
    font-size:0.875rem
}
.navbar .searchBox svg{
    position: absolute;
    height: 1rem;
    right: 1rem;
    top: 30%;
    z-index: 3;
}
/* .searchBox input{
    padding: 0 5.875rem; 
} */
.line{
    width: 2px;
    background-color: #eaedf3;
}

.publish-modal .modal-dialog {
    max-width: 73.75rem;
}

.navbar-shared .nav-link{
    font-size: 1rem;
    line-height: 1.5;
    color: #9c9c9c;
}
.navbar-shared .nav-link:hover{
    color: #9c9c9c !important;

}
.navbar .flags{
    border-right: 1px solid #eaedf3;
    border-left: 1px solid #eaedf3;
}
.btn-signup{
    border-radius: 3px;
    background-color: #d4af37;
    color:#fff;
    font-weight: bold;
}
.btn-signup:hover{
    color:#fff !important;
    
}
.btn-signup a{
    color:#fff !important;
    text-decoration: none !important
}
.searchBoxBorder{
    border-bottom: 1px solid #eaedf3;
    padding-bottom:1rem;
}
.navbar-shared{
    /* padding: 0  8rem 0 8rem; */
    /* height: 3.875rem !important; */
    padding: 0.625rem 0;
    border-bottom: 1px solid #eaedf3;
    background: #fff
}
/* .navbar-shared svg{
    cursor: pointer;
    fill: #BFBFBF
}  */
/* .navbar-shared svg:hover{
    fill: #d4af37;
} */
.navbar-flag-1:hover,
.navbar-flag-2:hover,
.navbar-flag-3:hover{
    fill: #d4af37;
    border-radius: 16px;
    background-color: #f6ebc9;
   
}
.navbar-flag-1{
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}
 /* .popover-body{
    padding: 0rem;
} */

#navbar-user-popover {
    min-width: 12rem;
    top: 10px !important;
}
.popover-links a,
.popover-links div{
    color: #9c9c9c;
    font-size: 0.75rem;
    padding:0.375rem 0.75rem;
    cursor: pointer;
}

.popover-links a:hover,
.popover-links div:hover{
    color: black;
    text-decoration: none;
    background-color:#FAF3E5;
   
}
/* .navbar-flag-2{
    padding-left: 0.5rem;
    padding-right: 0.4rem;   
}
.navbar-flag-3{
    padding-left: 0.5rem;
    padding-right: 0.4rem;  
} */
/* .bs-popover-bottom  .arrow::before,
.bs-popover-bottom  .arrow::after ,
.bs-popover-auto[x-placement^="bottom"]  .arrow::before,
.bs-popover-auto[x-placement^="bottom"]  .arrow::after{
    left:30px
} */

.navbar-profile-dropdown {
    position: absolute;
    background: #FFF;
    border-radius: 6px;
    left: 0;
    right: 0;
    z-index: 200;
    transform-origin: top;
    transform: scaleY(0);
    transition: transform .5s;
    /* animation: expand 1s; */
}
.navbar-profile-dropdown.open { transform: scaleY(1) }
.navbar-profile-dropdown ul {
    text-align: center;
    padding: 0;
    margin-top: 1.5rem;
}
.navbar-profile-dropdown ul li {
    list-style: none;
    line-height: 1.5;
}
.navbar-profile-dropdown ul li > a,
.navbar-profile-dropdown ul li > div
{color: #9c9c9c}

.navbar-profile-dropdown ul li:not(:last-child){
    margin-bottom: 1rem;
}
.message-indicator{
    display: inline-block;
    height: 0.375rem;
    width: 0.375rem;
    background-color: #4392f1;
    position: absolute;
    top: 0;
    right: 1.6rem;
    border-radius: 50%
}

@media (max-width: 1146px) {
    .navbar{
        height: auto !important;
    }
    .customer-logo{
        padding-bottom: 0.5rem !important
    }
} 
@media (max-width:992px){
    .logged-bar-nav{ width: 100%;}
    .navbar .flags{
        border:none
     }
    .navbar-shared .popover{
        display: none;
     }
}
 @media(min-width:992px){
    .searchBoxBorder{
        border-bottom: 0;
    }
 }

@media (max-width: 536px) {
 .searchBox  .navbar-search {
        transform: scaleX(1)
    }
}