#privacy-policy {
    margin-top: 3rem;
}
#privacy-policy .title{
    font-size: 1.8rem;
    font-weight: bold;
    color: #212121;
}
#privacy-policy .privacy-info{
    font-size: 1rem;
    font-weight: normal;
    color: #716d6d;
    margin-top: 2rem;
}
#privacy-policy h1{
    font-size: 1rem;
    font-weight: bold;
    color: #212121;
}
#privacy-policy p{
    font-size: 1rem;
    font-weight: normal;
    color: #716d6d;
}
#privacy-policy li{
    font-size: 1rem;
    font-weight: normal;
    color: #716d6d;
}
#privacy-policy .footer{
    margin-top: 3rem;
}