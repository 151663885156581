.load-bar {
    position: relative;
    width: 100%;
    height: 4px;
    background-color: #d4af37;
}
.bar {
    content: "";
    /* display: inline; */
    position: absolute;
    width: 0;
    height: 100%;
    left: 50%;
    text-align: center;
}
  .bar:nth-child(1) {
    background-color: #d4af37;
    -webkit-animation: loading 3s linear infinite;
    animation: loading 3s linear infinite;
  }
  .bar:nth-child(2) {
    background-color: #fff;
    -webkit-animation: loading 3s linear 1s infinite;
            animation: loading 3s linear 1s infinite;
  }
  .bar:nth-child(3) {
    background-color: #d4af37;
    -webkit-animation: loading 3s linear 2s infinite;
            animation: loading 3s linear 2s infinite;
  }
  
  @-webkit-keyframes loading {
      from {left: 50%; width: 0;z-index:100;}
      33.3333% {left: 0; width: 100%;z-index: 100;}
      to {left: 0; width: 100%;}
  }
  /* @keyframes loading {
      from {width: 0;z-index:100;}
      /* 33.3333% {width: 100%;z-index: 10;} */
      /* to {left: 0; width: 100%;} */
  /* }  */