.article-view .recommented-view .heading {
  /* border-top: 1px solid #f2f4f8; */
  border-bottom: 1px solid #f2f4f8;
}
.article-view .content-title {
  font-size: 2.312rem;
}
img.thumbnail-image {
  height: 25rem;
  object-fit: cover;
}
.article-view .premium-overlay {
  position: absolute;
  z-index: 1000;
  height: 100vh;
  width: 100vw;
  background-color: white;
  opacity: 0.7;
}
.article-view .recommented-view h5 {
  font-size: 21px;
  font-weight: bold;
}
.article-view .article-card:hover {
  transform: translateY(0rem) !important;
  box-shadow: none !important;
}

.article-view .article-card:hover .article-image {
  background-image: none !important;
}

.article-view .article-card:hover .article-image > img {
  z-index: none;
}
.article-view .trending-categories h6 {
  font-size: 1.312rem;
  font-weight: bold;
  border-bottom: 1px solid #eaedf3;
}

.article-view .user-details img {
  height: 2.25rem;
  width: 2.25rem;
  object-fit: cover;
  border-radius: 50%;
}
.trending-categories a {
  text-decoration: none;
  color: #9c9c9c;
}
.article-view .user-details .date,
.article-view .user-details p {
  font-size: 0.75rem;
  color: #9c9c9c;
}

.article-view blockquote {
  border-left: 6px solid #d4af37;
  padding-left: 19px;
}

.article-view .review-box {
  border-bottom: 1px solid #eaedf3;
}
.article-view .review-box .col {
  height: 2px;
  background: #eaedf3;
}
.article-view .review-box .rectangle {
  width: 9.4px;
  height: 9.4px;
  transform: rotate(-45deg);
  background-color: #eaedf3;
}
.article-view .review-box p {
  font-size: 0.75rem;
}

.article-section img,
.article-section iframe
{
  max-width: 100%;
}
.article-section iframe {
  width: 100%;
  height: 350px;
}

.video-content #bgvid {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  object-fit: cover; /* cover works perfectly on Safari */
}

.video-content .wrapper {
  width: 100%;
  min-width: 100%;
  height: 364px;
  max-height: 364px;
  overflow: hidden;
}
.article-view .content-description {
  color: #9c9c9c;
  padding-left: 2.8rem;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;

  text-overflow: ellipsis;
}
.article-view .see-more {
  padding-left: 2.8rem;
  color: var(--primary);
  font-weight: bold;
  cursor: pointer;
}
.article-view .catagory-chips {
  border-radius: 3px;
  background-color: #f4f4f4;
  cursor: pointer;
}
.article-view .share-icons .fb,
.article-view .share-icons .twitter {
  color: #212121;
  font-size: 1.25rem;
}
.article-view .share-icons a {
  text-decoration: none;
}
.article-view .share-icons .fb:hover {
  cursor: pointer;
  color: #385798;
}
.article-view .share-icons .twitter:hover {
  cursor: pointer;
  color: #00a0ed;
}

.article-view pre {
  background: #f1f1f1;
  border-radius: 3px;
  padding: 10px 10px;
}

[class*="_1d7iuxk"],
[class*="_1d7iuxk"]:hover {
  height: 50px !important;
  width: 50px !important;
  border-radius: 50% !important;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
._i5w4tsi,
[class*="track"]
[class*="bar"]:nth-child(2) {
  background-color: var(--primary) !important;
}

@media (max-width: 575px) {
  img.thumbnail-image {
    height: 18rem;
  }
  .video-content .wrapper {
    height: 250px;
  }
  .article-section iframe {
    height: 250px;
  }
  .article-view .content-title {
    font-size: 1.75rem;
  }
}
