textarea.gt-text-area {
    background: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #fff;
    color: #fff;
    padding: 0 55px 10px 0;
    position: relative;
    box-sizing: border-box;
    resize: none;
}
textarea.gt-text-area:focus{
    box-shadow: none;
    background: transparent;
    border-bottom: 2px solid #fff;
    color: #fff;
}

.text-area-ok {
    border: none;
    border-radius: 2px;
    background: #fff;
    position: absolute;
    right: 15px;
    bottom: 10px;
    font-size: 0.75rem;
    padding-right: 1.25rem;
    /* position: relative; */
}
.text-area-ok::after {
    position: absolute;
    content: '';
    z-index: 5;
    right: 5px;
    height: 1em;
    width: 1em;
    top: 50%;
    transform: translateY(-50%);
    background-image: url('/icons/tick-black.png');
    background-repeat: no-repeat;
    background-size: .5rem; 
    background-position: center;
}
textarea.gt-text-area::placeholder {
    color: #ffffff98;
}

form.assessment-form::-webkit-scrollbar{
    display: none !important;
    width: 0 !important;
    -ms-overflow-style: none !important;
}
form.assessment-form .question-list-item:last-child {
    margin-bottom: 65vh;
}
form.assessment-form{
    overflow: auto;
    max-height: calc(100vh - 5.25rem);
    padding-top: calc(50vh - 5.25em);
    scrollbar-width: none !important;
}

/* HELP LINE MODAL STYLES */
#helpline-modal .helpline {
    padding-left: 2.25rem;
}

#helpline-modal .state-title {
    color: #212121;
    font-size: 1.25rem;
    font-weight: bold
}
#helpline-modal .get-help-text {
    color: #212121;
    font-size: 1.5rem;
    font-weight: bold
}

#helpline-modal .helpline span {
    font-size: 0.875rem;
}
#helpline-modal .helpline span:first-child {
    color: #212121;
    font-weight: 600;
}
#helpline-modal .helpline::before {
    content: '\2022';
    font-size: 1.5rem;
    line-height: 1;
    color: var(--primary);
    position: absolute;
    left: 1rem;
}
#helpline-modal .helpline span:nth-child(2) {
    color: #9c9c9c;
    margin-left: 0.5rem;
}