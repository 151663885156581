.load {
  animation: loadContainer 0.3s ease;
}

@keyframes loadContainer {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
