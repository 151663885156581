.content-tab-card{
    background: #fff;
    border-radius: 0.25rem;
}
.content-tab-card nav {
    border-color: #eaedf3;
}
.content-tab-card .nav-tabs .nav-link{
    border: none;
    border-radius: 0;
    color: #a2a2a2;
    font-size: 0.75rem;
    font-weight: 700;
}
.content-tab-card .nav-tabs .nav-link.active{ 
    color: #222222;
    outline: none;
    border-bottom: 4px solid var(--primary)
}
.content-tab-card .nav-link {
    padding: 0;
    margin: 0.5rem 1rem;
    padding-bottom: 0.325rem;
    margin-bottom: 0;
}
.content-tab-card .title {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
}
.content-tab-card .saved-content-tab {
    max-height: 20rem;
    overflow: auto;
}