.checkout-container {
  min-height: 61vh;
}

.checkout-container .price-plan-detail {
  border-radius: 4px;
  background-image: linear-gradient(275deg, #d49437 100%, #d4af37);
  color: white;
}

.checkout-container .price-plan-detail-overlay {
  width: 100%;
  position: absolute;
  opacity: 0.8;
  border-radius: 4px;
  background-image: linear-gradient(310deg, #d49437, #d4af37);
  z-index: -9;
}

.checkout-container .price-plan-detail-image {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -10;
}

.nav-tabs {
  /* background-color: #777777 !important; */
  border-bottom: solid 1px #f2f4f8;
}

.checkout-details {
  border-radius: 4px;
  background-color: white;
}

.checkout-container .navitem.active {
  background-color: #777777;
}

.checkout-container .nav-tabs a {
  color: #9c9c9c !important;
}

.checkout-container .nav-tabs .nav-link:hover {
  border-color: transparent;
}
.checkout-container .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: black !important;
  border-color: transparent;
  /* border-bottom: solid 4px #d4af37; */
  background-color: transparent;
}

.checkout-container .nav-tabs .nav-link,
.nav-tabs .nav-item.show .nav-link {
  padding-left: 0px;
  padding-right: 3rem;
}

.checkout-container .success-pop {
  transition: 0.5s;
  animation: 0.8s successPop ease;
}

@-webkit-keyframes successPop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes successPop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
