.user-comments  img{
    height: 1.875rem;
    width: 1.875rem;
}
.user-comments .time,
.user-comments .comment-text{
    color: #9c9c9c;
    font-size: 0.75rem;
    white-space: pre-line;
}
