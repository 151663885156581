.gtm-form-control {
    background-color: transparent !important;
    border: none;
    border-bottom: 2px solid #fff !important;
    border-radius: 0;
    padding: 0.375em 0;
    text-align: left;
    color: #fff !important;
    position: relative;
    font-size: inherit;
}
.gt-primary{
    font-size: 1rem;
    color: #212121;
    font-weight: normal;
    height: 3em;
    border-radius: 3px;
    background-color: #fff !important;
    border: solid 1px #dedede !important;
    padding: 0.375rem 0.75rem;
    box-shadow: none !important;
    outline: none !important;
}
.gt-primary.invalid{
    box-shadow: 0 0 14px 0 rgba(255, 0, 0, 0.2) !important;
    border-color: #ff0000 !important;
}
.custom-select:focus {
    box-shadow: none;
    outline: none;
}
select > option {
    color: #212121;
    font-size: 1rem !important;
}
select.custom-select.gt-primary[datavalue=""] {
    color: #6c757c !important;
}
select.custom-select.gtm-form-control[datavalue=""] {
    color: #9c9f9e !important;
}