#resolving-conflict{
    margin-top: 3rem;
}
#resolving-conflict .title{
    font-size: 1.8rem;
    font-weight: bold;
    color: #212121;
}
#resolving-conflict .instructions-container{
        margin-top: 2rem;
}
#resolving-conflict .instructions{
    font-size: 1rem;
    font-weight: normal;
    color: #716d6d;
}
#resolving-conflict .sub-title{
    font-size: 1rem;
    font-weight: bold;
    color: #212121;
    margin-top: 1.5rem;
}
#resolving-conflict li{
    font-size: 1rem;
    font-weight: normal;
    color: #716d6d;
}