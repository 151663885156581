#prac_profile .prac_content{
  background-color: #ffffff;
  border: solid 1px #eaedf3;
}
#prac_profile .prac_content h6{
  background-color: #ffffff;
  padding: 1rem;
  font-weight: 600;
  color: #031121;
}
#prac_profile .prac_content_header{
  color: #031121;
  font-weight: 600;
}
#prac_profile .content_navbar li{
  margin-right: 2rem;
  list-style: none;
  color: #a2a2a2;
  font-size: 0.75rem;
  font-weight: 600;
}
#prac_profile .content_navbar {
  border-bottom: solid 1px #eaedf3;
  margin-bottom: 0;
}
#prac_profile .prac_content_search {
  border-bottom: solid 1px #eaedf3;
}
#prac_profile .tabs{
  border-bottom: solid 1px #eaedf3;
  background-color: #ffffff;
}
#prac_profile .nav-link {
  border-right: none !important;
}
#prac_profile .prac_content .tabs a{
  opacity: 0.5;
  font-size: 0.75rem;
  color: #a2a2a2;
  font-weight: 600;

}
#prac_profile .prac_content .nav-tabs .nav-link{
  padding: 0.5rem;
}
#prac_profile .prac_content .nav-tabs .nav-link.active span{
  color: #031121 !important;
}
#prac_profile .prac_content .nav-tabs .nav-link.active span svg path{
  fill: #031121 !important;
  opacity: 1 !important;
}
#prac_profile .prac_content .nav-tabs .nav-link.active{
  opacity: 1 !important;
}
#prac_profile .content_load {
  font-size: 0.75rem;
  color: #d4af37;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}
#prac_profile .prac_content .tabs a.active{
  border-bottom: 0.25rem solid #ccb385 !important;
  border-top-left-radius: 4px;
}
.content-tab-container {
  max-height: 25rem;
  overflow-y: auto;
  -webkit-transform: translate3d(0,0,0);
}
