#landing-page  section{
    margin-top: 2rem;
}
#landing-banner {
    height: 75vh;
    background-blend-mode: multiply;
    background-color: #00000080;
    background-image: url('/images/Header-Image.jpg');
    transition: background-image 2s ease-in;
    /* background-color: #000; */
    background-size: cover;
    background-position: center;
    color: #fff;
}
#landing-logo { 
    position: absolute;
    left: 2.175rem;
    top: 2.75rem;
    height: 1.175rem;
}

#landing-banner .banner-container {
    height: calc(100% - 64px);
    overflow: hidden;
}

#landing-banner .banner-content > h1 {
    font-family: 'Playfair Display', serif;
    font-size: 2.5rem;
    text-transform: capitalize;
    font-weight: bold;
    margin-bottom: 1rem;
}
#landing-banner .banner-content > p {
    line-height: 1.5;
    font-size: 1rem;
    margin: 0;
    padding: 0 22%;
}

#landing-banner .banner-button {
    text-align: center;
    border-radius: 6px;
    box-shadow: 0 2px 14px 0 rgba(193, 193, 193, 0.22);
    background-image: linear-gradient(350deg, rgb(212, 148, 55), rgb(212, 175, 55));
    cursor: pointer;
    padding: 1.25rem;
}

#landing-banner .banner-button h6 {
    font-size: 1.3215rem;
    margin: 0;
    line-height: 1.52;
    color: #fff;
    white-space: nowrap;
    text-decoration: none;
    font-weight: bold;
}
#landing-banner .banner-button p {
    font-size: 0.75rem;
    line-height: 1.5;
}

#landing-banner .banner-card {
    position: absolute;
    left: 8%;
    right: 8%;
    bottom: 1.5rem;
    box-shadow: 0 2px 14px 0 hsla(0, 0%, 76%, 0.22);
    background: #fff;
    border-radius: 15px;
}
#landing-banner .banner-card .banner-card-item a{
    text-decoration: none;
    font-size: 2.25rem;
    text-align: center;
    font-weight: bold;
    color: #342f20;
    margin: 0;
    line-height: 1.2;
}
#landing-banner .banner-card .banner-card-item p {
    margin: 0;
    color: var(--primary);
    text-align: center;
}

.landing-section-header > h4{
    margin: 0;
    color: #342f20;
    font-size: 1.75rem;
    font-family: 'Playfair Display', serif;
    font-weight: bold;
    line-height: 1.2;
}
.landing-section-header > p{
    margin: 0;
    color: #b3b3b3;
}

#focuses-section .focus-item {
    position: relative;
    display: inline-block;
    border-radius: 50%;
    cursor: pointer;
    transition: .6s;
}
#focuses-section .focus-item p {
    position: absolute;
    margin: 0;
    /* padding: 0 2.5rem; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;
    color: #fff;
    line-height: 1.5;
    width: 100%;
    text-align: center;
}
#focuses-section .focus-item img {
    border-radius: 50%;
    height: 10.625rem;
    width: 10.625rem;
}


#landing-register-now {
    background: #876d16;
    color: #fff;
    /* height: 5.75rem; */
}
#landing-register-now p{
    margin-right: 1.875rem;
    white-space: nowrap;
    font-size: 1.125rem;
    font-weight: bold;
}


/* NAVBAR */

.landing-navbar {
    padding-top: 2rem;
    align-items: center;
}
.landing-navbar .nav-logo {
    height: 4.25rem;
    position: absolute;
    bottom: 0;
    transform: translateY(50%);
}
.landing-navbar .navbar-links a,
.landing-navbar .navbar-links .signup
{
    font-size: 1rem;
    color: #fff;
    font-weight: 600;
}
.landing-navbar .navbar-links a:not(:last-child){
    margin-right: 1rem 
}
.landing-navbar .navbar-links .signup {
    padding: .35rem 1.75rem;
    border-radius: 40px;
    margin-right: 1rem; 
    background:  var(--primary);
}
.landing-navbar .navbar-links .signup:hover {
    text-decoration: underline;
}

.landing-navbar .logout-btn {
    background: none;
    font-weight: 600;
    color: #fff;
    border: none;
}

.navbar-search {
    position: relative;
    transform-origin: right;
    transition: 0.5s;
    z-index: 2;
    border-radius: 40px;
    background-color: #fff;
    height: 2.5rem;
}
.nav-bordered {
    border-radius: 3px;
    border: 1px solid #dedede;
}
.navbar-search-box svg{
    position: absolute;
    height: 1rem;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
}
.navbar-search input {
    border:none;
    font-size: 0.825rem;
    color: #212121;
}
.search-drop-down {
    font-size: 0.825rem;
    color: #212121;
    cursor: pointer;
}

.navbar-search .drop {
    min-width: 6rem;
    border-right: 1px solid #ececec;
}

.navbar-search .dropdown {
    z-index: 2;
    top: 1.75rem;
}

.navbar-search ul {
    padding: 0;
    margin-top: 0.25rem;
    background: #fff;
    border-radius: 0.25rem;
    width: 10rem;
    border: 1px solid #eaedf3;
}
.navbar-search ul > li {
    list-style: none;
    padding: .375rem .5rem;
    cursor: pointer;
}
.navbar-search ul > li:hover{
    background-color: rgba(212, 175, 55, 0.12)
}

.navbar-res-view {
    position: absolute;
    background: #000;
    left: 0;
    right: 0;
    z-index: 5;
    transform-origin: top;
    transform: scaleY(0);
    transition: transform .5s;
    /* animation: expand 1s; */
}
.navbar-res-view.open { transform: scaleY(1) }
.navbar-res-view ul {
    text-align: center;
    padding: 0;
    margin-top: 3rem;
}
.navbar-res-view ul li {
    list-style: none;
    line-height: 1.5;
}
.navbar-res-view ul li > a {color: #fff}
.navbar-res-view ul li:not(:last-child){
    margin-bottom: 2rem;
}

.navbar-res-view ul li:last-child {
    padding: .5rem 0;
    border-radius: 40px;
    background:  var(--primary);
}

/* how it works */

.bullet {
    height: 0.5rem;
    width: 0.5rem;
    min-width: 0.5rem;
}
.how-it-work-card .guide-text {
    font-size: 14px;
}
.show-more-slide {
    display: flex !important;
    width: auto !important;
    height: 317px;
}

@media (max-width: 768px) {
    .signup-selection img {
        height: 10rem;
    }
}

@media (min-width: 992px) {
    .col-lg-5c {
        flex: 0 0 20%;
        max-width: 100%;
    }
}

@media (max-width: 536px) {
    #landing-banner {
        height: 85vh;
    }
    #landing-banner .banner-content > p { padding: 0;}
    #landing-register-now p{ 
        margin-right: 0;
    }
    #focuses-section .focus-item img {
        height: 8.5rem;
        width: 8.5rem;
    }
}

/* Select User Type */
.signup-selection {
    border: 1px solid var(--primary);
    border-radius: 0.25rem;
    cursor: pointer;
    transition: 0.7s
}
.signup-selection:hover {
    transform: translateY(-0.25rem);
    box-shadow: 0 4px 8px 0 hsla(212, 83%, 7%, 0.11);
}

/* Skeleton */
.skeleton-section {
    height: 354px;
    overflow: hidden;
}
.banner-video {
    position: absolute;
    z-index: -99;
    background-color: #000;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
}
/* hide seek-bar */
.banner-video .overlay_1sxyvns,
.banner-video ._1sxyvns {
    display: none;
}
/* hide controller */
.banner-video .root_qwouf2,
.banner-video ._qwouf2  {
    display: none !important;
}