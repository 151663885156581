.social-btn{
    color: #fff;
    font-size: .825rem;
    font-weight: bold;
    /* height: 3.5em; */
    padding: .625rem 0.75rem;
    border-radius: 3px;
}
.social-btn:hover {
    color: #fff;
}
.facebook {
    background-color: #4b72bb;
}
.google {
    background-color: #d8523d;
}