#forgot-password img {
    width: 11.75rem;
    margin-bottom: 2rem;
    padding-left: 3.75rem;
}
#forgot-password form {
    padding: 3.75rem;
}
#forgot-password .link-sent-message {
    padding: 0.925rem 3.75rem;
    background-color: rgba(212, 175, 55, 0.164);
}