.question-list-item {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 6.4em;
  opacity: 0.15;
}
.question-list-item.active {
  opacity: 1;
}

.face-emoji {
  transition: 0.5s;
}

.face-emoji-active {
  opacity: 1;
  -webkit-animation: pop 0.3s ease;
  animation: pop 0.3s ease;
}

@-webkit-keyframes pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.face-emoji-inactive {
  opacity: 0.2;
}

form.progress-check-in-form::-webkit-scrollbar {
  display: none;
  width: 0 !important;
  -ms-overflow-style: none !important;
}
form.progress-check-in-form .question-list-item:last-child {
  margin-bottom: 65vh;
}
form.progress-check-in-form {
  overflow: auto;
  max-height: calc(100vh - 5.25rem);
  padding-top: calc(50vh - 5.25em);
  scrollbar-width: none !important;
}
