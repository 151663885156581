.qstn-answr h6{
    font-size: 1.312rem;
    cursor: pointer;
}
.qstn-answr svg{
    cursor: pointer;
}
.qstn-answr:hover{
    fill:#515456;
    opacity: 0.8
}
.qstn-answr svg{
    cursor: pointer;
}
