#prac_profile {
  background-color: #f2f4f8;
  min-height: 100vh
}
/* #prac_profile .nav_container {
  background-color: #ffffff;
  border-bottom: solid 1px #eaedf3;
} */
#prac_profile .prac_tags {
  padding-left: 1rem;
}