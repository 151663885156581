#prac_profile .prac_carousel {
  background-image: linear-gradient(to bottom, rgba(255, 213, 146, 0.801), rgba(185, 148, 88, 0.801)), url(/images/prac_carousel.png);
  background-size: cover;
  background-position: top;
  height: 10.56rem;
  padding: 1.125rem;
  font-family: SourceSansPro;
}
#prac_profile .prac_carousel h4{
  color: #fff;
  font-weight: 600;
}
#prac_profile .prac_carousel p {
  color: #ffffff;
  font-size: 1.125rem;
}
#prac_profile a.carousel-control-prev,
#prac_profile a.carousel-control-next {
  display: none !important;
} 
#prac_profile .prac_carousel .offer_days_left p{
  font-size: 0.935rem;
}
#prac_profile .prac_carousel .offer_image_div{
  height: 7.375rem;
  width: 7.375rem;
  background-color: #aa874e;
  border-radius: 0.25rem;
  padding-top: 0.75rem;
  text-align: center;
}
#prac_profile .prac_carousel .offer_image_div img{
  height: 4.125rem;
  width: 4.125rem;
}
#prac_profile .prac_carousel .offer_image_div p{
  opacity: 0.5;
  font-family: SourceSansPro;
  font-size: 0.875rem;
  letter-spacing: 3.9px;
  color: #fff;
  margin-top: 0.875rem;
}
.prac_user_offers .carousel-indicators li {
  border: none;
  height: .5rem;
  width: .5rem;
  border-radius: 50%;
}
.prac_user_offers .carousel-indicators .active {
  background-color: #000;
}