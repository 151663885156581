.autocomplete-dropdown-container {
    background-color: #ffffff;
    border: 1px solid #dedede;
    color: #222222;
}
.suggestion-item {
    padding: 0.5rem 0.625rem;
}
.suggestion-item-active {
    padding: 0.5rem 0.625rem;
    background-color: #fafafa;
}
.gtm-form-control + .autocomplete-dropdown-container {
    border: 0;
}