.notification-wrapper {
    position: relative;
}
#notifications-popover{
    position: absolute;
    top: 3.5rem;
    right: 0;
    background-color: #fff;
    z-index: 200;
    border: 1px solid #eaedf3;
    border-radius: 0.25rem;
    min-width: 30rem;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);
}
#notifications-popover::before {
    content: '';
    position: absolute;
    top:0;
    right: 1.25rem;
    width: 1rem;
    height: 1rem;
    background: #fff;
    border-left: 1px solid #eaedf3;
    border-top: 1px solid #eaedf3;
    /* box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12); */
    z-index: -1;
    transform: translateY(-50%) rotate(45deg);
}
.notifications {
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.notification-header { border-bottom: 1px solid #eaedf3; }
.notification-header .title {
    color: #212121;
    font-weight: 600;
    font-size: 1rem;
}
.notification-item:not(:last-child){
    border-bottom: 1px solid #eaedf3;
}
.notification-item.read {
    background-color: #f4eee5;
}
.notification-item .profile-image{
    height: 2.75rem;
    width: 2.75rem;
    min-width: 2.75rem;
}
.notification-item .message {
    color: #9c9c9c;
    line-height: 1.3;
}
.notification-item .message .sender {
    color: #212121;
    font-weight: bold;
}
.notification-item .date{
    font-size: 12px;
    color: #9c9c9c;
}
.btn-link.gt-btn-link {
    color: var(--primary)
}
.notification-indicater {
    display: inline-block;
    height: 0.375rem;
    width: 0.375rem;
    background-color: #4392f1;
    position: absolute;
    top: 0;
    right: 1.25rem;
    border-radius: 50%
}

@media (max-width: 991px) {
    #notifications-popover {
        /* position: static; */
        top: 2rem;
        right: unset;
    }
    #notifications-popover::before {
        display: none;
    }
}
@media (max-width: 536px) {
    /* .notification-wrapper {
        position: static;
    } */
    #notifications-popover {
        min-width: 92vw;
        /* right: 2vw; */
        left: 0;
        top: 2.5rem;
    }
}