#prac_profile .prac_availability_header button{
  border: none;
  color: #ccb385;
  font-size: 0.75rem;
  background-color: #fff;
}
#prac_profile .prac_availability {
  background-color: #ffffff;
}
#prac_profile .prac_availability_header {
  padding: 1rem;
  border: 1px solid #eaedf3;
  border-bottom: 0;
}
#prac_profile .prac_availability_header h6{
  font-weight: 600;
}
#prac_profile .prac_availability_body {
  padding: 1rem;
  border: 1px solid #eaedf3;
}
#prac_profile .prac_availability_body p{
  font-size: 0.75rem;
  color: #909090;
}
#prac_profile .prac_avail_slider p{
  font-size: 0.75rem;
  font-weight: 600;
  color: #484848;
}
#prac_profile .prac_schedule_section p{
  text-transform: uppercase;
  color: #031121;
  font-weight: 600;
}
#prac_profile .prac_schedule_section > div > p{
  color: #909090;
}