#link-business-complete h3 {
    font-size: 1.75rem;
    font-weight: bold;
    text-align: center;
}
#link-business-complete p {
    font-size: 1rem;
    text-align: center;
    padding: 0 4rem;
    margin-bottom: 3.5rem;
}
#link-business-complete img {
    max-height: 8rem;
    margin-bottom: 2.5rem;
}
.btn.gt-btn-link{
    background: #fff;
}

/* LINK EXIST BUSINESS */
#link-exist-business {
    /* margin-top: -10rem; */
}

#link-exist-business .or-text {
    font-size: 1rem;
    margin: 0;
    text-align: center; 
}

.link-practitioner-container{
    max-height: 100%;
    overflow: auto;
}

