.session-request-wrapper {
    position: relative;
}
#session-request-popover {
    position: absolute;
    top: 3.5rem;
    right: -2.5rem;
    background-color: #fff;
    z-index: 100;
    border: 1px solid #dcdcdc;
    border-radius: 0.25rem;
    min-width: 30rem;
    box-shadow: 0 0 14px 0 rgba(3, 16, 32, 0.27);

}
.session-requests .title {
    color: #212121;
    font-weight: 600;
    border-bottom: 1px solid #dcdcdc;
    font-size: 1rem;
}
.session-requests {
    position: absolute;
    top: 2.5rem;
    right: -2.5rem;
    background-color: #fff;
    z-index: 100;
    border: 1px solid #9c9c9c;
}
.session-request-item:not(:last-child) {
    border-bottom: 1px solid #dcdcdc;
}
.session-request-item .profile-image {
    height: 2.75rem;
    width: 2.75rem;
}
.session-request-item.new-item {
    background-color: #f4eee5;
}
.session-request-item .request-by {
    color: #212121;
    font-size: 1rem;
    font-weight: bold;
}
.session-request-item .date {
    font-size: 0.75rem;
    color: #9c9c9c
}
button.accepted {
    color: #876d16 !important;
    white-space: nowrap;
    background-color: rgba(212, 175, 55, 0.5);
    font-weight: bold;
    cursor: default;
}
button.accepted svg { margin-right: 0.5rem; }

.session-notification-indicater {
    display: inline-block;
    height: 0.375rem;
    width: 0.375rem;
    background-color: #4392f1;
    position: absolute;
    top: -0.2rem;
    right: -0.325rem;
    border-radius: 50%
}
#session-request-popover::before {
    content: '';
    position: absolute;
    top:0;
    right: 2.25rem;
    width: 1rem;
    height: 1rem;
    background: #fff;
    transform: translateY(-50%) rotate(45deg);
}
@media (max-width: 991px) {
    #session-request-popover {
        top: 2rem;
        right: unset;
        z-index: 1000;

    }
    #session-request-popover::before {
        display: none;
    }
}
@media (max-width: 536px) {
    /* .session-request-wrapper {
        position: static;
    } */
    #session-request-popover {
        min-width: 90vw;
        top: 2.5rem;
        right: 1rem;
        left: -2rem;
        z-index: 1000;
    }
}