#prac_profile .prac_offers {
  background-color: #ffffff;
}
#prac_profile .prac_offers_header {
  padding: 1rem;
  border: 1px solid #eaedf3;
  border-bottom: 0;
}
#prac_profile .prac_offers_header h6{
 font-weight: 600;
}
#prac_profile .prac_offers_body_container {
  padding: 1rem;
  border: 1px solid #eaedf3;
}
#prac_profile .prac_offers_body {
  background-color: #F0E8DB;
  padding: 1rem;
}
#prac_profile .prac_offers_body:not(:last-child){
  margin-bottom: 1rem;
}
#prac_profile .prac_offers_body h6{
  color: #b99458;
  font-weight: 600;
  font-size: 0.9rem;
}
#prac_profile .prac_offers_body p{
  color: #b99458;
  font-size: 0.75rem;
  margin-bottom: 0;
} 
#prac_profile .prac_offers_body .date{
  color: #000000;
} 