
#settings-list-item {
    border: solid 0.016rem #eaedf3;
    background-color: #ffffff;
    font-size: 1rem;
    font-weight: 600;
    color: #212121;
  }
  #settings-list-item svg{
      height: 1.25rem;
      margin-right: 0.5rem;
 }
.settings-link{
    color: #212121;
    width: 100%;
    display: block;
    padding: 1rem 1.5rem;
    padding-right: 1rem;
}
#settings-list-item .premium-membership{
    float: right;
    border-radius: 0.87rem;
    border: solid var(--primary) 0.06rem;
    padding: 0.125rem 0.125rem;
    font-size: 0.75rem;
    font-weight: bold;
    text-align: center;
    color: #212121;
}
.settings-link:hover{
    color: var(--primary);
}
.settings-list-item-active{ 
    position: relative;
    font-size: 1rem;
    font-weight: 600;
    color: var(--primary);
    background-color: #fdfbf3;
}
.settings-list-item-active svg path {
    stroke: var(--primary);
  }
.settings-list-item-active::before{
   position: absolute;
   content: '';
   left: 0;
   top: 0;
   bottom: 0;
   border: 0.1rem solid var(--primary)
}

  #user-settings-options .dropdown{
    transition: all 1s ease-in-out;
  }
  .dropdown-toggle{
      width: 100%;
  }
  #user-settings-options .dropdown-menu{
    width: 100%;
    z-index: 2000;
    transform-origin: top;
    transform: scaleY(0);
    transition: transform .5s;
    /* transition: all 0.5s ease-in-out; */
    /* overflow: hidden; */
  }

  /* .show > .dropdown-menu {
    max-height: 900px;
    visibility: visible;
  } */

  #user-settings-options .dropdown-menu a{
      color: #212121;
      text-align: center;
  }
  /* #user-settings-options #dropdown-options a{
    color: #212121;
    text-align: center;
  } */
  #user-settings-options #dropdown-options a:active{
      color: red;
  }

  #settings-dropdown-options .dropdown-toggle{
    background-color: var(--primary);
    padding: 0.625rem 1rem;
    border-color: #eaedf3;
    margin-bottom: 1rem;
    font-weight: 600;
  }
  #settings-dropdown-options .dropdown-divider { margin: 0;}
  #settings-dropdown-options .dropdown-item { padding: 0.625rem 1rem; }
  #settings-dropdown-options .dropdown-item.active { 
    background-color: var(--primary);
    color: #ffffff;
    font-weight: 600;
  }

  