.landing-footer {
    min-height: 16.5rem;
    background: #342f20;
    color: #fff;
}
#navigation-list{
    padding-top: 3.5rem;
}
#navigation-list li{
    list-style: none;
    font-weight: 600;
}
#navigation-list li a { color: #fff}

#navigation-list li:not(:last-child)::after {
    content: '\2022';
    margin: 0 1.875rem;
    color: #876d16;
    font-size: 1.3rem;
    line-height: 1;
}
.landing-footer p.footer-content {
    font-size: .75rem;
    margin: 2.375rem 0;
}

.landing-footer p.footer-content span {
    display: block;
}
.landing-footer .landing-footer-info { padding-bottom: 3.25rem; }

.landing-footer .landing-footer-info a {
    color: #fff;
    font-weight: 600
}

@media (max-width: 992px) { 
    #navigation-list li::after { 
        content: unset !important;
    };
}