#prac_profile .prac_business{
  background-color: #ffffff;
  margin-top: 1.25rem;
  border: solid 1px #eaedf3;
}
#prac_profile .prac_business_header h6{
  background-color: #ffffff;
  padding: 1rem;
  font-weight: 600;
  color: #031121;
}
#prac_profile .prac_business_header{
  color: #031121;
  font-weight: 600;
  padding: 1rem;
  border-bottom: 0;
}
#prac_profile .prac_buisness_details {
  margin-right: 1rem;
  margin-left: 1rem;
  border-bottom: solid 1px #eaedf3;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
#prac_profile .prac_buisness_details p {
  font-size: 0.75rem;
  margin-bottom: 0;
  color: #9c9c9c;
}
#prac_profile .prac_buisness_details img{
  height: 3.625rem;
  width: 3.625rem;
}
#prac_profile .prac_buisness_details h5{
  font-size: 1.31rem;
  font-weight: 600;
}
#prac_profile .prac_members p{
  font-size: 0.75rem;
  color: #bfbfbf;
  font-weight: 600;
  text-transform: uppercase;
  padding: 1rem;
}
#prac_profile .prac_mem_count {
  height: 3rem;
  width: 3rem;
  background-color: #d4af37;
  margin-left: -0.725rem;
  border-radius: 50%
}
#prac_profile .prac_mem_count p{
  color: #fff !important;
  size: 1rem;
}
#prac_profile .prac_mem_icon img {
  height: 3rem;
  width: 3rem;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #eaedf3;
}
#prac_profile .prac_mem_icon{
  margin-left: -0.725rem;
}

#prac_profile .prac_mem_icon:hover::before {
  content: attr(data-name);
  position: absolute;
  color: #fff;
  background-color: #222222;
  padding: .25rem .65rem;
  font-size: 0.75rem;
  top: 1.5rem;
  border-radius: 0.25rem;
}
