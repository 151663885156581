
/* Business settings */
#practitioner-business-settings{
    /* margin-top: 1.87rem; */
}
#practitioner-business-settings .practitioner-details{
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 0.2rem;
}
#practitioner-business-settings .profile-header{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
#practitioner-business-settings .profile-form{
    margin-top: 2.5rem;
  }

#practitioner-business-settings .profile-image{
    margin-right: 2rem;
}
#practitioner-business-settings .delete-picture{
    margin-left: 1rem;
    border: 0;
    font-size: 1rem;
    font-weight: normal;
    color: #9c9c9c;
    outline: 0;
}
.form-sub-title{
    font-size: 0.75rem;
    font-weight: bold;
    color: #9c9c9c;
}
.delete-account-button{
    padding: 0;
    margin-top: 1.5rem;
}
.delete-button-title{
    margin-left: 0.25rem;
    vertical-align: middle;
    font-size: 1rem;
    font-weight: normal;
    color: #9c9c9c;
    padding-left: 0; 
}

.practitioner-time-details{
    padding: 2rem;
    background-color: #ffffff;
    /* margin-top: 1.25rem; */
    border-radius: 0.2rem;
}
.practitioner-profile-image{
    height: 2.5rem;
    width: 2.5rem;
    object-fit: cover;
    border-radius: 50%;
}
.practitioner-time-title{
    font-size: 1.27rem;
    font-weight: bold;
    color: #212121;
}
.practitioner-time-subtitle{
    font-weight: normal;
    color: #9c9c9c;
}
.practitioner-time-table{
    margin-top: 3rem;
}
.practitioner-table-head{
    font-weight: 600;
}
.practitioner-name{
    font-size: 1rem;
    font-weight: normal;
    color: #292929;
}
.practitioner-email{
  font-size: 0.75rem;
  font-weight: normal;
  color: #9c9c9c; 
}
.practitioner-date-added{
    font-size: 1rem;
    font-weight: normal;
    color: #9c9c9c;
}


/* Promotions */
#promotions-settings{
    /* margin-top: 3.87rem; */
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 0.2rem;
}
#promotions-settings .promotions-title{
    font-size: 1.27rem;
    font-weight: bold;
    color: #212121;
}
.promo-modal-title{
    text-align: center;
    font-size: 1.21rem;
    font-weight: bold;
    color: #212121;
}
.promo-label{
    font-size: 0.75rem;
    font-weight: bold;
    color: #9c9c9c;
}
.promo-modal-description{
    font-size: 1rem;
    font-weight: normal;
    text-align: center;
    color: #9c9c9c;
  }
.empty-promotion-container{
    padding: 4rem 0;
}
.promotion-card{
    height: 12rem;
    border-radius: 0.2rem;
    background-color: rgba(185, 148, 88, 0.21);
    padding: 1rem;
}
.promo-popover{
    background-color: #ffffff;
    padding: 0.25rem;
    padding-right: 2.4rem;
    border-radius: 0.2rem;
}
.promo-popover-item-delete{
  font-size: 0.75rem;
  font-weight: normal;
  color: #ff0000;
  padding: 0;
  text-align: left;
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; */
}
.promo-popover-item-delete:hover{
    color: #ff0000;
}
.promo-popover-item-delete svg{
    height: 0.75rem;
}
.promo-popover-item-edit{
    font-size: 0.75rem;
    font-weight: normal;
    padding: 0;
    text-align: left;
  }
  .promo-popover-item-edit svg{
      height: 0.75rem;
  }
.promo-options{
    position: absolute;
    top: 0.5rem;
    right: 1rem;
}
.promotion-card-description{
    font-size: 1.27rem;
    font-weight: bold;
    color: #b99458;
    margin-left: 1rem;
}
.promotion-end-date{
    font-size: 0.75rem;
    font-weight: normal;
    color: #9c9c9c;
    position: absolute;
    bottom: 0.75rem;
}
.promotion-end-date-container{
    border-radius: 0.75rem;
    background-color: rgba(185, 148, 88, 0.21);;
    font-size: 0.75rem;
    font-weight: normal;
    color: #212121;
    padding-left: 0.5rem;
    padding: 0.25rem 0.5rem;
    position: absolute;
    bottom: 0.5rem;
    right: 1.5rem;
}
/* Shared-results */
#shared-results{
    /* margin-top: 3.87rem; */
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 0.2rem;
}
.shared-results-title{
    font-size: 1.27rem;
    font-weight: bold;
    color: #212121;
}
.shared-results-subtitle{
    font-weight: normal;
    color: #9c9c9c; 
}

/* Sales-history */
#sales-history{
    /* margin-top: 3.87rem; */
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 0.2rem;
}

/* Blocked-accounts */
#blocked-accounts{
    /* margin-top: 3.87rem; */
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 0.2rem;
}
#blocked-accounts .blocked-accounts-title{
    font-size: 1.27rem;
    font-weight: bold;
    color: #212121;
}
#blocked-accounts .blocked-accounts-subtitle{
    font-weight: normal;
    color: #9c9c9c;
}
.blocked-accounts-table{
    margin-top: 2.5rem;
}