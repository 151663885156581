.commentComponent{
  border-bottom:  1px solid #eaedf3;
}

  .commentComponent h6{
    font-weight: 600;
  }
  .commentComponent textarea::placeholder{
      font-size: 0.75rem;
      color: #9c9c9c;
  }
  .comentBox:focus{
    border: solid 1px var(--primary);
    outline: none;
    box-shadow: none;
  }