.purchase-modal {
  max-height: 40rem;
  transition: 1s;
  overflow: hidden;
}

.purchase-modal-content-hide {
  max-height: 0rem;
  opacity: 0;
}

.purchase-modal .purchase-head-image {
  height: 19.2rem;
  /* overflow: hidden; */
}

.purchase-modal .purchase-head-image .head-image-size,
.purchase-modal-payment .head-image-size {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.purchase-modal .preimum-icon-position {
  position: absolute;
  bottom: -2.5rem;
}

.purchase-modal .preimum-icon-container {
  width: 5.6rem;
  height: 5.6rem;
  background-image: linear-gradient(315deg, #d49437, #d4af37);
  border-radius: 5rem;
}

.purchase-modal-footer {
  background-color: #f6f6f6;
  border-top: none;
}

.purchase-modal .navitem.active {
  background-color: #777777;
}

.purchase-modal .nav-tabs a {
  color: #9c9c9c !important;
}

.purchase-modal .nav-tabs .nav-link:hover {
  border-color: transparent;
}
.purchase-modal .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #d4af37 !important;
  font-weight: 600;
  border-color: transparent;
  border-bottom: solid 1px #d4af37;
  background-color: transparent;
}

.purchase-modal .nav-tabs .nav-link,
.nav-tabs .nav-item.show .nav-link {
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 1rem;
  margin-left: 1rem;
}

.purchase-modal-payment .label {
  font-size: 0.75rem;
}

.purchase-modal-payment .feed-description {
  line-height: 1.5;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.purchase-modal-payment-type {
  transform: translateY(5rem);
  opacity: 0;
  transition: all 1s ease;
  height: 0px;
}

.purchase-modal-payment-type-show {
  transform: translateY(0);
  opacity: 1;
  height: 350px;
}
.purchase-modal .purchase-back-button {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  text-decoration: none;
  background-color: #fff;
  height: 1.75rem;
  width:  1.75rem;
}
.purchase-back-button i {
  font-size: .75rem;
}
