.btn-scale {
    border: 1px solid #fff;
    border-radius: 6px;
    transition: .3s;
}
.btn-scale {
    color: #fff;
    background-color: rgba(255, 255, 255, 0);
    height: 3.75em;
    width: 3.75em;
}
.btn-scale:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.25);
}
.btn-scale.active {
    color: #031121;
    background-color: rgba(255, 255, 255, 1);
    /* animation: flicker 0.25s ease 0s 2 normal none running; */
}
.additinal-info-field { width: 650px; }

/* .range-group::before {
    content: 'Not at all';
    position: absolute;
    font-size: 12px;
    font-weight: 400;
    left: 0;
    bottom:-1.5em;
}
.range-group::after {
    content: 'Extremely';
    position: absolute;
    font-size: 12px;
    font-weight: 400;
    right: 0;
    bottom:-1.5em;;
} */

.range-group .btn-scale::before{
    content: '';
    position: absolute;
    font-size: 12px;
    color: #fff;
    font-weight: 400;
    left: 0;
    bottom:-1.75em;
}

.range-group .btn-scale:not(.often):first-child::before { content: 'Not at all'; }
.range-group .btn-scale:not(.often):last-child::before { content: 'Extremely'; right: 0; text-align: right }

.range-group .btn-scale.often:nth-child(1)::before{content: 'Never'}
.range-group .btn-scale.often:nth-child(3)::before{content: 'Rarely'}
.range-group .btn-scale.often:nth-child(5)::before{content: 'Sometimes'}
.range-group .btn-scale.often:nth-child(7)::before{content: 'Regulary'}
.range-group .btn-scale.often:nth-child(9)::before{content: 'Often'}
.range-group .btn-scale.often:nth-child(11)::before{content: 'Frequently'; right: 0;}


/* RADIO BUTTON STYLES */

.radio-option label {
    position: relative;
    border-radius: 6px;
    font-size: .75rem;
    border: solid 1px #979797;
    cursor: pointer;
    padding: 0.625em 3.75em 0.625em 3em;
    min-width: 14em;
    text-transform: capitalize; 
}
.radio-option label:hover {
    background-color: rgba(255, 255, 255, 0.14);
}
.radio-option label::before {
    content: 'A';
    position: absolute;
    height: 1.65em;
    width: 1.65em;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #031121;
    top: 50%;
    left: 0.625em;
    transform: translateY(-50%)
}
.radio-option input:checked +  label::after{
    content: '';
    position: absolute;
    height: 1.65em;
    width: 1.65em;
    background-image: url('/icons/tick-white.png');
    background-repeat: no-repeat;
    background-position: center;
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    right: 0.625em;
    transform: translateY(-50%);
    /* animation: flicker 0.25s ease 0s 2 normal none running; */
}
.radio-option:nth-child(1) label::before { content: 'A' }
.radio-option:nth-child(2) label::before { content: 'B' }
.radio-option:nth-child(3) label::before { content: 'C' }
.radio-option:nth-child(4) label::before { content: 'D' }
.radio-option:nth-child(5) label::before { content: 'E' }
.radio-option:nth-child(6) label::before { content: 'F' }

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
input:-webkit-autofiill:focus-within,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    box-shadow: 0 0 0px 1000px white inset;
    transition: background-color 5000s ease-in-out 0s !important;
}

.gtm-invalid-feedback{
    width: 100%;
    border-radius: 2px;
    background-color: hsla(0, 0%, 100%, 0.6);
    color: #ff0000;
    font-size: 60%;
    padding: 0 0.25rem;
    position: relative;
    animation: errorAnimate 0.3s;
}
.gtm-invalid-feedback::after {
    content: '';
    position: absolute;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background: #ff0000;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%)
}

@keyframes flicker {
    50% { opacity: 0.1;}
}

@media (max-width: 760px) {
    .btn-scale {
        height: unset;
        width: unset;
        padding: 0.35rem 0;
        font-size: 12px;
    }
    .range-group { width: 100% }

    .range-group::before {
        content: 'Not at all';
        position: absolute;
        font-size: 12px;
        font-weight: 400;
        left: 0;
        bottom:-1.5em;
    }
    .range-group::after {
        content: 'Extremely';
        position: absolute;
        font-size: 12px;
        font-weight: 400;
        right: 0;
        bottom:-1.5em;
    }

    .range-group .btn-scale::before{content: unset !important}
    .range-group.often-group::before { content: 'Never'; }
    .range-group.often-group::after { content: 'Frequently'; }

    .radio-option label {
        min-width: 11em;
    }

    .question-list-item {
        font-size: 1rem;
        margin-bottom: 4.4em;
    }
    .additinal-info-field { width: unset; }
}