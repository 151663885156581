.slider-title {
    font-family: 'Playfair Display', serif;
}
@media (max-width: 768px) { 
    .slider-title {
        font-size: 1.5rem;
    }
}
.left-align-slick > .slick-slider > .slick-list > .slick-track {    
    margin-left:0;
}
.slick-prev.c-slick-prev,
.slick-next.c-slick-next {
    top:-40px;
}
.slick-prev.c-slick-prev::before,
.slick-next.c-slick-next::before {
    color: black;
    font-style: normal;
}
.slick-prev.c-slick-prev {
    right: 2.5rem;
    left: unset;
}
.slick-next.c-slick-next {
    right: 1rem;
}
