.modal-body  .head{
    font-size: 1.75rem;
    font-weight: bold;
    line-height: 1.5;
    text-align: center;
    color: #212121;
}
 .modal-body .description{
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    color: #9c9c9c;
  }
.modal-body .footer p{
    font-size: 0.75rem;
    line-height: 2;
    color: #9c9c9c;
}
.tooltip-inner{
    max-width:308px !important;
}
.content-form h6{
    font-size: 0.75rem;
    font-weight: bold;
    color: #9c9c9c;
}
.content-form input::placeholder{
    font-size: 1rem;
    /* color: #212121;; */
}
.content-form textarea.form-control{
    height: 11.437rem;
}

.modal-body .btn-cancel{
    color: #9c9c9c;
}


.dragzone h5{
    opacity: 0.8;
    font-size: 21px;
    font-weight: bold;
}
.dragzone p{
    opacity: 0.8;
    font-size: 12px;
    color: #9c9c9c;
}

.dragzone:focus{
    outline: none
}

#bgvid {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    object-fit: cover; /* cover works perfectly on Safari */
}
  
.publish-vedio-wrapper {
    width: 100%;
    min-width: 100%;
    height: 364px;
    max-height: 364px;
    overflow: hidden;
}

.publish-content-form-wrapper .progress{
    height: 10rem;
    background-color: #fff9e5;
}
.publish-content-form-wrapper .progress-bar {
    background-color:  #fff0c0;
    color: #212121;
    font-size: 1.25rem;
    font-weight: bold;
}
.publish-content-form-wrapper .progress-bar-striped {
    background-size: 2.5rem 2.5rem;
}
.publish-success {
    width: 28rem;
}