@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?cq45i');
  src:  url('fonts/icomoon.eot?cq45i#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?cq45i') format('truetype'),
    url('fonts/icomoon.woff?cq45i') format('woff'),
    url('fonts/icomoon.svg?cq45i#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-check_circle_outline:before {
  content: "\e91c";
}
.icon-check_circle:before {
  content: "\e921";
}
.icon-times:before {
  content: "\e90a";
}
.icon-times-circle:before {
  content: "\e910";
}
.icon-assessment:before {
  content: "\e90c";
}
.icon-order-history:before {
  content: "\e91e";
}
.icon-hamburger:before {
  content: "\e905";
}
.icon-bell:before {
  content: "\e929";
}
.icon-mail:before {
  content: "\e92e";
  font-size: 0.75rem;
}
.icon-chat:before {
  content: "\e925";
}
.icon-session:before {
  content: "\e926";
}
.icon-lock-alt:before {
  content: "\e911";
}
.icon-clock:before {
  content: "\e901";
}
.icon-percentage_large:before {
  content: "\e902";
}
.icon-cloud-upload:before {
  content: "\e903";
}
.icon-percentage:before {
  content: "\e904";
}
.icon-settings:before {
  content: "\e906";
}
.icon-article1:before {
  content: "\e907";
}
.icon-phone:before {
  content: "\e908";
}
.icon-website:before {
  content: "\e909";
}
.icon-at:before {
  content: "\e90b";
}
.icon-instagram:before {
  content: "\e90d";
}
.icon-trash:before {
  content: "\e90e";
}
.icon-message:before {
  content: "\e90f";
}
.icon-twitter:before {
  content: "\e912";
}
.icon-map:before {
  content: "\e913";
}
.icon-user:before {
  content: "\e914";
}
.icon-info:before {
  content: "\e915";
}
.icon-star:before {
  content: "\e916";
}
.icon-facebook:before {
  content: "\e917";
}
.icon-date-picker:before {
  content: "\e918";
}
.icon-search:before {
  content: "\e919";
}
.icon-bookmark:before {
  content: "\e91a";
}
.icon-audio:before {
  content: "\e91b";
}
.icon-video:before {
  content: "\e91d";
}
.icon-upload:before {
  content: "\e91f";
}
.icon-home:before {
  content: "\e920";
}
.icon-plus-circle:before {
  content: "\e922";
}
.icon-tick:before {
  content: "\e923";
}
.icon-back-arrow:before {
  content: "\e924";
}
.icon-angle-right:before {
  content: "\e927";
}
.icon-angle-left:before {
  content: "\e928";
}
.icon-search-navbar:before {
  content: "\e92a";
}
.icon-plus:before {
  content: "\e92b";
}
.icon-minus:before {
  content: "\e92c";
}
.icon-arrow:before {
  content: "\e92d";
}
.icon-article:before {
  content: "\e900";
}
.icon-menu:before {
  content: "\e9bd";
}
.icon-star-empty:before {
  content: "\e9d7";
}
.icon-arrow-up:before {
  content: "\ea3a";
}
.icon-arrow-right:before {
  content: "\ea3c";
}
.icon-arrow-down:before {
  content: "\ea3e";
}
.icon-arrow-left2:before {
  content: "\ea40";
}
.icon-circle-up:before {
  content: "\ea41";
}
.icon-circle-right:before {
  content: "\ea42";
}
.icon-circle-down:before {
  content: "\ea43";
}
.icon-circle-left:before {
  content: "\ea44";
}