#email-inputs input.form-control {
    background: none;
    border: none;
    font-size: 1.25rem;
    border-radius: 0;
    border-bottom: 2px solid #fff;
    padding: .5rem 0;
}
#email-inputs.primary input.form-control {
    font-size: 1rem;
    color: #212121;
    height: 3em;
    border-radius: 3px;
    background-color: #fff !important;
    border: solid 1px #dedede;
    padding: 0.375rem 0.75rem;
    box-shadow: none;
    outline: none;
}

#email-inputs .email-input-wrapper::after {
    content: 'Press "enter/return" to add email';
    position: absolute;
    font-size: 0.75rem;
    right: 0;
    bottom: 0.25rem;
}
#email-inputs.primary .email-input-wrapper::after {
    bottom: -1rem;
}
#email-inputs input.form-control::placeholder { color: #fff; }
#email-inputs input.form-control:focus { 
    color: #fff;
    border-color: var(--primary);
}

#email-inputs.primary input.form-control::placeholder { color: #6c757c; }
#email-inputs.primary input.form-control:focus { 
    color: #212121;
}

.selected-emails span {
    background: #fff;
    color: #212121;
    padding: 0.25rem 0.825rem;
    border-radius: 2rem;
    font-size: 1rem;
    font-weight: 600;
    margin-right: .5rem;
    margin-bottom: .5rem;
}
.selected-emails span > img {
    height: .625rem;
    margin-left: .25rem;
    margin-bottom: .1rem;
    cursor: pointer;
}

#email-inputs.primary .selected-emails span {
    background: #eaedf3;
    color: #212121;
}