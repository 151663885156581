#practitioner-registration-container {
    height: 100vh;
    background-blend-mode: multiply;
    background-color: #00000080;
    background-image: url('/images/Practitioner_background.jpg');
    transition: background-image .5s ease-in;
    background-size: cover;
    background-position: center; 
    color: #fff;
}

.practitioner-create-profile {
    height: calc(100vh - 5.25rem);
    overflow-y: auto;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}
.practitioner-create-profile::-webkit-scrollbar { width: 0 !important }

.pract-reg-header { margin-bottom: 2rem}
.pract-reg-header h3 {
    font-size: 2.25rem;
    font-weight: bold;
    margin-bottom: .25rem; 
}

.pract-reg-header p {
    font-size: 1rem;
    margin: 0;
}

#practitioner-registration-container .prac-reg-label {
    margin-bottom: 0;
}

.tell-us-more h2 {
    font-size: 3rem;
    font-weight: bold;
    margin: 0;
}
.tell-us-more p { 
    text-decoration: underline;
    font-weight: 600;
    margin: 0;
    font-size: 1rem;
    cursor: pointer;
}

.profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #dedede;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.profile-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

/* PRACTITIONER REG COMPLETE */
#pract-reg-complete h2 {
    line-height: 1.18;
    font-weight: bold;
    margin-bottom: 3rem;
}

#pract-reg-complete h4 {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.18;
    margin-bottom: .5rem;
}

#pract-reg-complete p {
    padding: 0 30%;
    font-size: 1rem;
    margin-bottom: 1.5rem;
}

#pract-reg-complete .links > a:first-child {
    text-decoration: none;
    padding: .625rem 1.25rem;
    background: #fff;
    color: #212121;
    border-radius: 6px;
    /* margin-right: 1.875rem; */
    font-size: 1rem;
}
#pract-reg-complete .links > a:nth-child(2){ 
    color: #fff;
    font-size: 1rem;
}


.certificate-selected {
    border: 1px solid #fff;
    border-radius: 6px;
    padding: .5rem .75rem;
    position: relative;
    background-color: rgba(255, 255, 255, 0.2);
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.certificate-selected div {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%,-50%);
    height: 1rem;
    width: 1rem;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.certificate-selected div svg {
    width: .5rem;
    height: .5rem;
}

.certificate-selected p:nth-child(2) {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
}
.certificate-selected p:nth-child(3){
    margin: 0;
    font-size: 0.75rem;
}

/* TIME AVAILABILTY */

#time-availability .day {
    margin-bottom: 1.75rem;
}

#time-availability .day label {
    position: relative;
    border-radius: 6px;
    font-size: 1rem;
    border: solid 1px #979797;
    cursor: pointer;
    padding: 0.625rem 1.25em;
    min-width: 6.25em;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    transition: .2s; 
}
#time-availability .day-choosed {
    position: absolute;
    width: 100%;
    top: -2rem;
    font-size: 0.625rem;
    font-weight: 600;
    padding: 0.25rem 0.325rem;
    text-align: center;
    border: 1px solid #fff;
    background: #fff;
    color: #212121;
    border-radius: .325rem; 
}

#time-availability .day-choosed::after {
    content: '';
    height: 8px;
    width: 8px;
    background: #fff;
    position: absolute;
    right: 50%;
    bottom: 0;
    transform: translate(50%,50%) rotate(45deg) ;
}

#time-availability .day label:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

#time-availability .day input:checked + label { 
    background-color: #fff;
    color: #031121;
}

#time-availability .day input:checked +  label::after{
    content: '';
    height: 1em;
    width: 1em;
    background-image: url('/icons/tick-black.png');
    background-repeat: no-repeat;
    background-position: center;
    margin-left: .5em;
}
#time-availability .title-message {
    font-size: 16px;
}
#time-availability .title-message > span {
    font-weight: bold;
    margin: 0 .25rem;
}

#time-availability .title-message > span:not(:last-child)::after {
    content: ',';
}

.slider-container {
    padding: 1rem;
    border: 1px solid #fff;
    border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.2);
    position: relative;
}

#time-availability .slider-container::before {
    border: 0.75rem solid #fff;
    border-color: transparent;
    border-bottom-color: #fff;
    position: absolute;
    right: 50%;
    top: -1.5rem;
    z-index: 10;
}


/* PRACTITIONER REG FOOTER */

.prac-reg-footer{
    height: 5.25em;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}
.prac-reg-footer::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: url('/images/Practitioner_background.jpg');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
    filter: blur(13px);
    -webkit-filter: blur(13px);
}

/*TIME AVAILABILITY - varient-primary */
#time-availability.primary .day input:checked + label { 
    background-color: #fff8ec;
    border-color: var(--primary);
    color: #212121;
}
#time-availability.primary .day label:hover {
    background-color: #fff8ec;
}
#time-availability.primary .day-choosed{
    position: absolute;
    width: 100%;
    top: -2rem;
    font-size: 0.625rem;
    font-weight: 600;
    padding: 0.25rem 0.325rem;
    text-align: center;
    border: 1px solid #fff;
    background: #031121;
    color: #fff;
    border-radius: .325rem; 
}
#time-availability.primary .day-choosed::after {
    content: '';
    height: 8px;
    width: 8px;
    background: #031121;
    position: absolute;
    right: 50%;
    bottom: 0;
    transform: translate(50%,50%) rotate(45deg) ;
}

#time-availability.primary .slider-container {
    border-color: var(--primary);
}
@media (max-width: 536px) {
    .cert-del-btn {
        border-bottom: 1px solid #dedede;
        margin-bottom: 2rem;
    }
}