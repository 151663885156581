.gt-radio-option label,
.gtm-radio-option label {
    position: relative;
    border-radius: 6px;
    font-size: 1rem;
    border: solid 1px #979797;
    cursor: pointer;
    padding: 0.625rem 1em;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    transition: .2s; 
}

.gtm-radio-option label:hover {
    background-color: #fff8ec;
    border-color: #d4af37
}
.gt-radio-option label:hover {
    background-color: rgba(255, 255, 255, 0.2);
}
.gtm-radio-option label.selected,
.gtm-radio-option input:checked + label{
    background-color: #fff8ec;
    border-color: #d4af37 
}


.gt-radio-option label.selected,
.gt-radio-option input:checked + label { 
    background-color: #fff;
    color: #031121;
}
.gtm-radio-option label.selected::after,
.gtm-radio-option input:checked +  label::after,
.gt-radio-option label.selected::after,
.gt-radio-option input:checked +  label::after{
    content: '';
    height: 1em;
    width: 1em;
    background-image: url('/icons/tick-black.png');
    background-repeat: no-repeat;
    background-position: center;
    margin-left: .5em;
}
label.selected .other-text-field { color: #031121; }
.other-text-field { 
    color: #031121;
    background: transparent !important;
    border: none;
    color: #fff;
}