.practitioner-card {
    border-color: #eaedf3;
    height: 23.5rem;
    cursor: pointer;
    transition: .7s
}
.practitioner-card .card-body{
    padding: 1rem
}
.practitioner-card .rate-section{
    border-radius: 9px;
    border: solid 1px #b99458;
    font-size: .75rem;
    padding: 0 2px;
}
.practitioner-card:hover{
    /* transform: translateY(-0.5rem); */
    box-shadow: 0 4px 8px 0 hsla(212, 83%, 7%, 0.11);
}
.practitioner-card-content {
    /* height: calc(100% - 1.25rem); */
    flex: 1
}
.practitioner-card-content img {
    border-radius: 50%;
    height: 10rem;
    width: 10rem;
    margin-bottom: .5rem;
}
.practitioner-card-content h5 {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
    color: #212121;
    line-height: 1.5
}
.practitioner-card-content p {
    line-height: 1.5;
    color: #9c9c9c;
    margin-bottom: .625rem;
    font-size: 0.75rem
}
.percentage-popover{
    background: #f4eee5;
    border: none;
    max-width: 15.81rem;
}
.percentage-popover p{
    margin-left: .75rem;
    color: #b99458;
}
.percentage-popover .arrow::after{
    border-right-color: #f4eee5
}
.percentage-popover .arrow::before{
 border-right-color: #ffffff
}
.practitioner-card .catagory-chips{
    border-radius: 3px;
    background-color: #eaedf3;
    cursor: pointer;
    color:#9c9c9c;
    font-size: 0.75rem
}
.practitioner-card-content p::before{
    content: url('/images/open-quote.png');
    position: relative;
    left: .5rem;
    top: .5rem;
}
/* .practitioner-card-content p::after{
    content:  url('/images/close-quote.png');
    position: absolute;
    margin-left: -1.125rem;
    bottom: 0;
} */
.practitioner-card .truncate{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.practitioner-card-content .user-image{
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
    object-fit: cover;
}
.practitioner-card .practitioner-card-footer{
    background-color: rgba(185, 148, 88, 0.21);
}
.practitioner-card .practitioner-card-footer p {
    color:  #b99458;
    line-height: 1.25;
}
.tags{
    background-color: #eaedf3;
    font-size: 12px;
    color: #9c9c9c;
    border-radius: 3px;
}


/* PRACTITIONER SKELETON */
.practitioner-skeleton-card {
    border-color: #eaedf3;
}