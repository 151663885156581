.progress-check-in-card {
    border-radius: 0.25rem;
    color: #fff;
    background-image: linear-gradient(317deg, rgb(212, 148, 55), rgb(212, 175, 55));
}
.progress-check-in-card .gt-link:hover {
    color: var(--primary);
}

.progress-check-in-card h4{
    font-size: 1.25rem;
    font-weight: bold;
}

.progress-check-in-card .start-now {
    border: 1px solid #fff;
    color: #fff;
    border-radius: 40px;
}