#prac_profile .prac_review{
  background-color: #ffffff;
  margin-top: 1.25rem;
  border: solid 1px #eaedf3;
}
#prac_profile .prac_review_header h6{
  background-color: #ffffff;
  padding: 1rem;
  font-weight: 600;
  color: #031121;
}
#prac_profile .prac_review_header{
  color: #031121;
  font-weight: 600;
  padding: 1rem;
  border-bottom: 0;
}
#prac_profile .reviewer_icon img {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
}
#prac_profile .reviewer_details h6{
  font-weight: 700;
  color: #031121;
}
#prac_profile .reviewer_details_bottom p{
 margin-bottom: 0;
 color: #bfbfbf;
 font-size: 0.75rem;
}
#prac_profile .prac_review_body p{
  color: #9c9c9c;
  white-space: pre-line;
}
/* #prac_profile .reviewer_details_bottom .published_date::before{
  content: '\2022';
  margin: 0 4px;
} */
.prac_review .review_load {
  font-size: 0.75rem;
  color: #d4af37;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}
.card_block {
  max-height: 25rem;
  overflow-y: auto;
}
.card_block .prac_review_card:not(:last-child) .prac_review_content {
   border-bottom: solid 1px #eaedf3;
}