.content_section {
  background-color: #ffffff;
  height: 9rem;
  overflow: hidden;
  margin-bottom: 0.5rem;
  /* padding: 1rem; */
  /* border-bottom: solid 1px #eaedf3; */
}
.content_icon{
  height: 100%;
  width: 9.25rem;
  object-fit: cover;
}
.content-type-icon{ 
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 10;
}
.content-thumbnail {
  height: 11.25rem;
  width: 11.25rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.content_section_desc h5{
  font-size: 1.15rem;
  font-weight: 600;
  color: #212121;
}
.content_section_desc p{
  color: #9c9c9c;
  overflow: hidden;
  font-size: 14px;
  max-width: 100% !important;
  /* autoprefixer: off; */
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  word-break: break-all;
  /* width: 346px; */
}
.content_section_desc {
  /* padding: 1rem;
  padding-top: 0; */
}
.content_section_footer {
  font-size: 0.75rem;
  color: #212121;
}
.content_footer_right {
  color: #9c9c9c;
}
.content_rating span {
  margin-left: 0.25rem;
}
.content_footer_date::before{
  content: '\2022';
  margin: 0 4px;
}
.content_section .content-popover {
  background-color: #ffffff;
  padding: 0.5rem 1rem;
  right: 0.75rem;
  top: 1.75rem;
}

@media (max-width: 576px) {
  .content_section { height: 7.25rem; }
  .content_icon { width: 7.25rem; }
}