.face-dimension {
  width: 45px;
  height: 45px;
}

.face-container {
  border-radius: 6px;
  border: solid 1px #ffffff;
  width: fit-content;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.face-content {
  font-size: 0.8rem;
}

.tick {
  position: absolute;
  top: -1px;
  right: -2px;
  animation: slideTick 0.3s ease;
}

@-webkit-keyframes slideTick {
  0% {
    top: -25px;
    right: -25px;
  }
  100% {
    top: -1px;
    right: -2px;
  }
}

@keyframes slideTick {
  0% {
    top: -25px;
    right: -25px;
  }
  100% {
    top: -1px;
    right: -2px;
  }
}
