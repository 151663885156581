.pricing-card {
  height: 15.56rem;
  border-radius: 4px;
  box-shadow: 0 2px 22px 0 rgba(205, 205, 205, 0.51);
  border: solid 1px #eaedf3;
  background-color: #ffffff;
  cursor: pointer;
  transition: 0.3s;
}
.pricing-card .price {
  font-size: 2.5rem;
  font-weight: bold;
  color: #d4af37;
}
.pricing-card:hover {
  /* transform: translateY(-0.5rem); */
  box-shadow: 0 4px 8px 0 hsla(212, 83%, 7%, 0.11);
  border-radius: 3px;
  border: solid 1px #d4af37;
  transform: translateY(-10px);
}
.price-btn {
  border-color: #d4af37;
}

.price-btn:hover {
  background-color: #d4af37;
  color: #ffffff;
}
