.card-container {
  border-radius: 4px;
  border: solid 1px #eaedf3;
  background-color: #ffffff;
}

.card-container-membership {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top-width: 0px;
}

.delete:hover path {
  fill: #6b6b6b;
}

.card-icon {
  width: 2rem;
}

.default-card {
  font-size: 0.65rem;
  background-color: #d4a002;
  border-radius: 0.1rem;
  color: white;
  height: 1rem;
}

.more-icon {
  width: 1rem;
  cursor: pointer;
}

#card-details-popover {
  min-width: 9rem;
  top: 10px !important;
}
.popover-links a,
.popover-links div {
  color: #9c9c9c;
  font-size: 0.75rem;
  padding: 0.375rem 0.75rem;
  cursor: pointer;
}

.popover-links a:hover,
.popover-links div:hover {
  color: black;
  text-decoration: none;
  background-color: #faf3e5;
}
