.report-content{
    border-bottom: 1px solid #eaedf3;
    /* border-top: 1px solid #eaedf3; */
    transition: 1s;
}
.report-content h6{
    font-weight: 600
}
.report-content .radio-container {
    display: block;
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: .75rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .report-content .radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .report-content .checkmark {
    position: absolute;
    top: 0.34rem;
    left: 0;
    height: 0.875rem;
    width: 0.875rem;
    background-color: #fff;
    border-radius:.75rem;
    border: solid 1.5px #9c9c9c
  }

  .report-content .radio-container:hover input ~ .checkmark {
    background-color: #FAF6E8;
  }
  .report-content .radio-container input:checked ~ .checkmark {
     border-color: var(--primary)
     
  }
  .report-content .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .report-content .radio-container input:checked ~ .checkmark:after {
    display: block;
  }
  .report-content  .radio-container .checkmark:after {
    left: 1px;
    top: 1px;
    width: 0.5rem;
    height: 0.5rem;
    background-color: var(--primary);
    border-radius:.75rem;
  }
  .report-content .btn-next{
      color:  var(--primary)
  }
  .report-content .form-control:focus{
    border-color: #dedede;
  }
  .report-content .complete-report p{
    font-size: .75rem;
    color: #9c9c9c
  }