#prac_profile .prac-profile-card {
  background-color: #ffffff;
  border: 1px solid #eaedf3;
  border-radius: .25rem;
  /* margin-top: 1.25rem; */
}
#prac_profile .prac_basic_info {
  padding-top: 2.5rem;
  /* border: solid 1px #eaedf3;
  border-radius: 3px; */
}
#prac_profile .prac_about_pic img{
  border-radius: 50%;
  height: 9rem;
  width: 9rem;
  object-fit: cover;
  border: 1px solid #eaedf3;
}
#prac_profile .prac_name {
  font-weight: 600;
  font-size: 1.3rem;
  text-transform: capitalize;
}
#prac_profile .prac_category {
  font-size: 0.75rem;
  color: #909090;
  margin-bottom: 1rem;
  text-transform: capitalize;
}
/* #prac_profile .prac_info_2 {
  border: solid 1px #eaedf3;
} */
#prac_profile .prac_about_section{
  border-bottom: solid 1px #eaedf3;
}
#prac_profile .prac_about_section p {
  font-weight: 600;
  font-size: 0.75rem;
}
#prac_profile .prac_info_2 {
  color: #bfbfbf;
}
#prac_profile .prac_about_me span {
  font-size: 0.75rem;
  font-style: italic;
}
#prac_profile .prac_category span:not(:last-child)::after {
  content: ',';
  margin-right: 0.25rem;
}
#prac_profile .prac_contact_info {
  font-size: 0.85rem;
  word-break: break-word;
}
#prac_profile .prac_about_section button {
  border: none;
  color: #ccb385;
  font-size: 0.75rem;
  background-color: #ffffff;
}
#prac_profile .prac_about .connect_button button, #prac_profile .prac_about .connect_button button:hover {
  background-color: #b99458;
  color: #fff;
  border-radius: 0.375rem;
  width: -webkit-fill-available;
  font-weight: 600;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  outline: none;
}

.btn-outline-white {
  border: 1px solid #e6e6e6;
  color: #9c9c9c;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  
}
.btn-outline-white:hover {
  /* color: var(--primary); */
  /* font-weight: 600; */
  background-color: #FFF9E6;
}