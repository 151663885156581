.gt-btn{
    /* font-size: 0.75rem; */
    font-weight: bold;
    /* line-height: 1; */
    padding: .625rem 1.5625rem;
    /* height: 3.425em; */
    color: #fff;
    border-radius: 3px;
    transition: .2s;
    white-space: nowrap;
    background-color: var(--primary)
}
.gt-btn:hover{
    color: #fff;
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
}
.gt-btn:active {
    background-color: var(--primary-active);
}
.gt-btn.large {
    font-size: 1.125rem;
    /* padding: 0.75rem 1.2rem; */
    font-weight: 600;
    /* height: unset; */
}
.outline {
    border: 1px solid #fff;
    background-color: transparent;
}
.gt-btn-sm {
    padding: 0.25rem 1rem;
    font-size: 0.75rem;
    line-height: 1.5;
    border-radius: 0.15rem
}
.gt-btn-white {
    color: #212121;
    background-color: #fff;
    border-color: #d8d8d7;
}