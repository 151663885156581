/* Profile settings */
#practitioner-profile-settings .practitioner-profile-settings-details{
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 0.2rem;
}
#practitioner-profile-settings .delete-picture{
    margin-left: 1rem;
    border: 0;
    font-size: 1rem;
    font-weight: normal;
    color: #9c9c9c;
    outline: 0;
}
#practitioner-profile-settings .profile-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* background-color: #ffffff; */
}
.professional-services{
    margin-top: 1.25rem;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 0.2rem;
}
.professional-services .professional-title{
  font-size: 1.27rem;
  font-weight: bold;
  color: #212121;
}
#practitioner-profile-settings .profile-image{
    margin-right: 2rem;
}

#practitioner-profile-settings .sub-title{
    font-size: 0.75rem;
    font-weight: bold;
    color: #9c9c9c;
}
#practitioner-profile-settings .specialities-description{
    font-size: 0.75rem;
    font-weight: normal;
    color: #9c9c9c;
}
#practitioner-profile-settings .delete-button-title{
    margin-left: 0.5rem;
    vertical-align: middle;
    font-size: 1rem;
    font-weight: normal;
    color: #9c9c9c;
    padding-left: 0; 
}
#practitioner-profile-settings .time-description{
    font-size: 1rem;
    font-weight: normal;
    color: #9c9c9c;
}
.set-time{
    font-size: 1rem;
    font-weight: bold;
    color: #121212;
    margin-left: 0.5rem;
}
#practitioner-profile-settings .professional-settings{
    flex: 1;
}
@media (max-width: 768px) { 
    #practitioner-profile-settings .practitioner-profile-settings-details{ padding: 1rem; }
    .professional-services { padding: 1rem; } 
}