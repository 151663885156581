input[type='checkbox'].switch-checkbox {display: none;}
.switch-label { 
    color: #fff;
    font-size: 1.25rem;
    font-weight: bold;
    /* margin-right: 4rem; */
    position: relative;
}
label.switch {
    position: relative;
    display: inline-block;
    width: 2rem;
    height: 1.125rem;
    border: 1px solid #d8d8d8;
    border-radius: 40px;
    margin: 0;
    background-color: #ffffff33;
    transition: 0.3s ease-in-out;
}
label.switch::before {
    content: '';
    display: inline-block;
    position: absolute;
    height: 1.125rem;
    width: 1.125rem;
    border-radius: 50%;
    background: #fff;
    top: -1px;
    left: -1px;
    z-index: 1;
    transition: 0.3s ease-in;
    justify-content: center;
}
label.dark-switch {
    background-color: #d8d8d8;
    width: 2.5rem;
    height: 1.5rem;
}
label.dark-switch::before { 
    background: #fff;
    width: 1.3rem;
    height: 1.3rem;
    top: 0;
    left: 0;
}
input[type='checkbox'].switch-checkbox:checked + label.switch::before {
    transform: translateX(0.95rem);
}
input[type='checkbox'].switch-checkbox:checked + label.dark-switch::before {
    transform: translateX(1rem);
}
input[type='checkbox'].switch-checkbox:checked + label.switch{
    border-color: var(--primary);
    background-color: var(--primary); 
}