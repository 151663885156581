.search-multi-dropdown-control {
    background: none;
    border: none;
    font-size: 1.25rem;
    border-radius: 0;
    color: #fff;
    border-bottom: 2px solid #fff;
    padding: .15rem 0;
    padding-top: 0;
    height: unset; 
}
.gt-form-control-primary {
    font-size: 1rem;
    color: #9c9c9c;
    font-weight: normal;
    border-radius: 3px;
    background-color: #fff !important;
    border: solid 1px #dedede;
    padding: 0.625rem 0.75rem;
    box-shadow: none;
    outline: none;
}

.gt-form-control-primary .search-multi-dropdown-input {
    color: #212121;
}
.gt-form-control-primary .search-multi-dropdown-input:focus {
    color: #212121;
}
.gt-form-control-primary .search-multi-dropdown-input::placeholder { color: #9c9c9c; }


.search-multi-dropdown-input{
    background: none;
    border: none;
    border-radius: 0;
    color: #fff;
    min-width: 1rem;
    padding: 0;
}

.search-multi-dropdown-input:focus {
    color: #fff;
    background: transparent;
}
.search-multi-dropdown-input::placeholder { color: #ffffff98; }


.search-multi-dropdown-control .selected-item {
    background: #fff;
    color: #212121;
    padding: 0.25rem 0.825rem;
    border-radius: 2rem;
    font-size: 1rem;
    font-weight: 600;
    margin-right: .5rem;
    margin-bottom: .35rem;
    text-transform: capitalize;
}
.search-multi-dropdown-control .selected-item img{
    height: .625rem;
    margin-left: .25rem;
    margin-bottom: .1rem;
    cursor: pointer;
}

.gt-form-control-primary .selected-item {
    background: #eaedf3;
}


ul.search-multi-dropdown-box{
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 0.15rem;
    margin-bottom: .5rem;
    background: #fff;
    color: #212121;
    max-height: 30vh;
    overflow: auto;
    border: 1px solid #dbdbdb;
    z-index: 100;
}
ul.search-multi-dropdown-box {
    padding: 0;
}
ul.search-multi-dropdown-box li {
    list-style: none;
    padding: 0.325rem .75rem;
    font-size: 1rem;
    cursor: pointer;
    text-transform: capitalize;
}
ul.search-multi-dropdown-box li:hover{
    background-color: hsla(46, 65%, 52%, 0.12);
}
ul.search-multi-dropdown-box li.active{
    font-weight: 600;
    color: #fff;
    background-color: var(--primary);

}