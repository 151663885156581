.category-view .heading{
    font-size: 3.0625rem;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
}
.category-view .count{
    font-size: 21px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
}
.category-view .type{
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    color: #ffffff;
}
.category-view .navbar-search input ,
.category-view  .search-drop-down,
.category-view .navbar-search ul > li {
    font-size: 14px;
   
}
 .tabs{
    border-bottom: solid 1px #eaedf3;
    background-color: #ffffff;
}

.tabs a{
    border-left: 0 !important;
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-radius:none;
    border-right:1px solid #eaedf3 !important;
    opacity: 0.5;
    font-size: 16px;
    line-height: 1.5;
    color: #9c9c9c;
    font-weight: bold;

}
.tabs a:last-child{
    border-right: 0 !important;
}

.nav-tabs .nav-link.active span{
    color: #d4af37 !important;
  
}
.nav-tabs .nav-link.active span svg path{
    fill: #d4af37 !important;
    opacity: 1 !important;
}
.nav-tabs .nav-link.active{
    opacity: 1 !important;
}
.filtered-content .section-header h4{
    font-family: Source Sans Pro;
}
.slick-slide{
    padding-right: 15px;
    padding-left: 15px;
}
.category-content .article-card:hover{
    transform: translateY(0rem) !important;    
    box-shadow: none !important;
}

.category-content .article-card:hover .article-image {
    background-image:none !important
}

.category-content  .article-card:hover .article-image > img {
    z-index: none;
}
.category-content  .practitioner-card:hover{
    transform: translateY(0rem) !important;    
    box-shadow: none !important;
}
#settings::-webkit-scrollbar {
    width: 0px;
    height: 0px;
 }
 
 #settings::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
 }
 
 #settings::-webkit-scrollbar-thumb {
    height: 100px;
    background-color: #d8d4d4;
    outline: 1px solid slategrey;
 }