.assesment-welcome-container {
    height: 100vh;
    background-blend-mode: multiply;
    background-color: #00000080;
    background-image: url('/images/assesment-blur.jpg');
    transition: background-image .5s ease-in;
    background-size: cover;
    background-position: center; 
    color: #fff;
}
.question-list-item{
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 6.4em;
    opacity: 0.15;
    overflow: hidden;
}
.question-list-item.active {
    opacity: 1;
}

.assessment-footer{
    height: 5.25em;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}
.assessment-footer::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: url('/images/Assessment_background.jpg');
    background-position: bottom;
    filter: blur(10px);
    -webkit-filter: blur(10px);
}
.nav-arrow-btn {
    background-color: #fff;
    padding: .35rem 1rem;
    line-height: 1;
    border: none;
    border-radius: 2px;
    /* width: 1.75em; */
}
.welcome-container {
    max-height: 100vh;
    overflow-y: auto;
}
.welcome-container::-webkit-scrollbar {
    display: none;
    width: 0 !important;
}