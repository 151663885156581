.contacts-card {
    background: #fff;
    border-radius: 0.25rem;
}
.contacts-card .contacts-card-header h6 {
    font-weight: bold;
    margin: 0
}

.contacts-card-body .empty-meaage{
    font-size: 0.75rem;
    color: #9c9c9c;
}

/* AVATAR */
.contacts-card .avatarr p {margin: 0}
.contacts-card .avatarr .specialisation{
    color: #9c9c9c;
    font-size: 0.75rem;
}
.contacts-card .avatarr .specialisation span:not(:last-child)::after{
    content: ',';
    margin-right: .25rem;
}
.contacts-card .avatarr .avatar {
    height: 2.75rem;
    width: 2.75rem
}
.primary-link-btn{
    color: var(--primary);
    font-size: 0.75rem;
    font-weight: 600;
}
.btn-browse-pract{
    width: 100%;
   }
@media(max-width:992px){
    .btn-browse-pract{
    width: unset;
    }
}