.validation-badge {
    position: absolute;
    right: .75rem;
    top: .75rem;
    z-index: 2;
    font-size: 1.5rem;
    /* transform: translateY(-50%); */
}
.validation-badge.material {
    font-size: 1.5rem;
    right: 0;
}