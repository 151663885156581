/* #keyword-inputs input.form-control {
    background: none;
    border: none;
    font-size: 1.25rem;
    border-radius: 0;
    border-bottom: 2px solid #fff;
    padding: .5rem 0;
} */

#keyword-inputs .keyword-input-wrapper::after {
    content: 'Press "enter/return" to add keyword';
    position: absolute;
    font-size: 0.75rem;
    right: 0;
    bottom: -1.25rem;
}

#keyword-inputs input.form-control::placeholder { color: #9c9c9c; }
#keyword-inputs input.form-control:focus { 
    color: #000;
    border-color: var(--primary);
}

.selected-keywords span {
    background: #eaedf3;
    color: #212121;
    padding: 0.25rem 0.825rem;
    border-radius: 2rem;
    font-size: 1rem;
    font-weight: 600;
    margin-right: .5rem;
    margin-bottom: .5rem;

}
.selected-keywords span > img {
    height: .625rem;
    margin-left: .25rem;
    margin-bottom: .1rem;
    cursor: pointer;
}