.prac_review_card .reviewer_icon img {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  object-fit: cover;
}
.prac_review_card .reviewer_details h6{
  font-weight: 700;
  color: #031121;
}
.prac_review_card .reviewer_details_bottom p{
 margin-bottom: 0;
 color: #bfbfbf;
 font-size: 0.75rem;
}
.prac_review_card .prac_review_body p{
  /* border-bottom: solid 1px #eaedf3; */
  color: #9c9c9c;
  font-size: 0.875rem;
}
/* .prac_review_card .reviewer_details_bottom .published_date::before{
  content: '\2022';
  margin: 0 4px;
} */