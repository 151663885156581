@import 'icomoon.css';
:root {
    --primary: #d4af37;
    --primary-hover: #d4a002;
    --primary-active: #d49400;
}

body{
    font-family: 'Source Sans Pro', sans-serif;
    color: #212121;
}
button, input:focus {
    box-shadow: none !important;
    outline: none !important;
}

.font-bold {
    font-weight: bold;
}
.btn-link {
    color: var(--primary);
}
.btn-link:hover {
    color: var(--primary-hover);
}
.assessment-footer .progress {
    background-color: rgba(255,255,255,0.2);
    height: .25rem;
}
.assessment-footer .progress-bar {
    background-color: #fff !important;
}

.bg-primary {
    background-color: var(--primary) !important;
}
.bg-white {
    background-color: #fff !important;
}

.cursor-pointer { cursor: pointer;}

h1, .h1 {
    font-size: 2.25rem;
    font-weight: bold;
}
h2, .h2 {
    font-size: 1.75rem;
    font-weight: bold;
}
.text-primary {
    color: var(--primary) !important;
}
.text-secondary {
    color: #9c9c9c !important;
}
.text-dark {
    color: #212121 !important;
}
.display1 {
    font-size: 1.3125rem;
}
.display2 {
    font-size: 0.75rem;
}
.display3 {
    font-size: 0.625rem;
}
.font-medium {
    font-weight: 600;
}
.btn-outline-primary {
    color: #212121;
    border-color: var(--primary);
}
.btn-outline-primary:hover,
.btn-outline-primary:active {
    color: #212121 !important;
    background-color: #fff8ec !important;
    border-color: #d4af37 !important;
}
.btn-outline-primary:disabled { color: unset; }
.border-gt {
    border-color: #eaedf3 !important;
}

.btn-outline-secondary {
    color: #212121 !important;
    border-color: #cccaca;
    background-color: #fff !important;
}
.btn-primary {
    color: #fff;
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
}

/* Skeleton */

.shine {
    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px; 
    display: inline-block;
    position: relative; 
    
    animation-duration: 1s;
    animation-fill-mode: forwards; 
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
}

@-webkit-keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }
    
    100% {
        background-position: 468px 0; 
    }
}

.img-placeholder {
    height: 8rem;
    width: 8rem;
}

.img-placeholder-small {
    height: 7rem;
    width: 7rem;
    min-width: 7rem;
}
.img-placeholder-sm {
    height: 3rem;
    width: 3rem;
}
.img-placeholder-xm {
    height: 2rem;
    width: 2rem;
}
.line-placeholder {
    height: 10px;
    width: 64px;
    margin: 4px 0;
    align-self: center;
}
.line-placeholder-100 {
    width: 100%;
}
.line-placeholder-75 {
    width: 75%;
}
.line-placeholder-50 {
    width: 50%;
}
.line-placeholder-10 {
    width: 10%;
}

.line-placeholder-long {
    width: 200px;
}
.line-placeholder-small {
    width: 25px;
}
.line-placeholder-smaller {
    width: 15px;
}
.line-placeholder-smallest {
    width: 10px;
}
.header-placeholder-h1 {
    height: 28px;
}
.header-placeholder-h2 {
    height: 18px;
}

.text-vertical-truncate {
    /* autoprefixer: off; */
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    word-break: break-word
}

.line-1 {
    -webkit-line-clamp: 1 !important;
}
.line-2 {
    -webkit-line-clamp: 2 !important;
}
.line-3 {
    -webkit-line-clamp: 3 !important;
}

@media (min-width: 992px) {
    .line-lg-1 {
        -webkit-line-clamp: 1 !important;
    }
    .line-lg-2 {
        -webkit-line-clamp: 2 !important;
    }
    .line-lg-3 {
        -webkit-line-clamp: 3 !important;
    }
}

@media (min-width: 768px) {
    .line-md-1 {
        -webkit-line-clamp: 1 !important;
    }
    .line-md-2 {
        -webkit-line-clamp: 2 !important;
    }
    .line-md-3 {
        -webkit-line-clamp: 3 !important;
    }
}