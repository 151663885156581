.dropzone{
    height: 22.75rem;
    border-radius: 3px;
    border: dashed 2px #e4e4e4;
    background-color: #fffefa;
}
.dropzone:focus{
  border: dashed 2px var(--primary);
  outline: none;
}
