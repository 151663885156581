.selection-card{
    border-radius: 10px;
    border: solid 1px #d4af37;
    cursor: pointer;
    transition: .7s

}
.selection-card .type{
    font-size: 21px;
    font-weight: bold;
    line-height: 1.52;
    color: #212121
}
.selection-card span{
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: #9c9c9c;
}
.selection-card:hover{
    transform: translateY(-0.5rem);
    box-shadow: 0 5px 24px 0 hsla(212, 83%, 7%, 0.27);
}