.carousel-indicators li {
    border: none;
    height: 8px;
    width: 8px;
    border-radius: 50%;
}
.carousel-indicators li:nth-child(3) {
    width: 24px;
    border-radius: 4px;
}
.carousel-indicators .active {
    background-color: #d4af37;
}

.testimonial-text{
    font-weight: bold;
    text-align: center;
}

.testimony img[alt='testimony'] {
    height: 2.25rem;
    width: 2.5rem;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 1rem
}