.modal-title{
    font-size: 21px;
    font-weight: bold;
    line-height: 1.52;
    color: #212121;
}
.modal-header button:focus{
    outline: none !important
}
.publish-modal .modal-dialog {
    max-width: 73.75rem;
}
.feedback-modal .modal-dialog{
    max-width: 40.5rem;
}