.signup-banner{
    background-image: linear-gradient(to top, #031121, rgba(3, 17, 33, 0)), url('/images/signup.png');
    background-blend-mode: multiply;
    background-size: cover;
    background-position: center;
}

.logo-text {
    color: #031121;
    font-weight: bold;
}

.signup-text-box h4 {
    color: #212121;
    font-size: 1.75rem;
    font-weight: bold;
}
.signup-text-box p{
    /* font-size: 1.125rem; */
    color: #212121;
    font-weight: normal;
}
.checkbox-text{
    font-size: .75rem;
    color: #212121;
    font-weight: normal;
}
.checkbox-text-tc {
    /* text-decoration: none; */
    color: var(--primary);

}
.checkbox-text-tc:hover {
    color: var(--primary)
}
.signup-footer {
    position: absolute;
    bottom: 0;
    transform: translateX(50%);
}

ul.gt-feature-list{
    padding: 0;
    margin: 0;
    margin-top: 5em;
}
ul.gt-feature-list li {
    list-style: none;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.9;
    color: #fff;
    margin-bottom: 1.905em;
    padding-left: 2em;
    text-transform: capitalize;
    position: relative;
}
ul.gt-feature-list li::before{
    position: absolute;
    content: '';
    display: inline-block;
    left:0;
    top: .45em;
    height: 1em;
    width: 1em;
    border-radius: 50%;
    background: #fff;
    background-image: url('/icons/tick.svg');
    background-repeat: no-repeat;
    background-size: .5em; 
    background-position: center; 
}

.auth-footer {
    color: #212121;

}
.auth-footerlink {
    color: var(--primary);
    font-weight: 600;
}
.auth-footerlink:hover {
    color: var(--primary);
}

.oauth-container::after {
    position: absolute;
    content: '';
    border-bottom: solid 1px #dedede;
    bottom: 0;
    right: 15%;
    left: 15%;
}
.oauth-container::before {
    position: absolute;
    content: 'OR';
    bottom: 0;
    z-index: 1;
    padding: 0 .5rem;
    color: #212121;
    font-size: .75rem;
    left: 50%;
    background-color: #fff;
    transform: translate(-50%, 50%);
}

#practitioner-signup-banner .guide {
    position: relative; 
    padding-left: 1.5rem;
    color: #fff;
 }
#practitioner-signup-banner .guide::before {
    content: '';
    height: .875em;
    width: .875em;
    border-radius: 50%;
    background-color: #fff;
    background-image: url('/icons/tick.svg');
    background-repeat: no-repeat;
    background-size: .5em; 
    background-position: center; 
    position: absolute;
    left: 0;
    top: .35em;
}
#practitioner-signup-banner .guide h5,
#practitioner-signup-banner .guide p
{
    font-size: 1rem;
    line-height: 1.5;
    margin: 0;
}
#practitioner-signup-banner .guide h5 { 
    font-weight: bold;
    margin-bottom: .25rem; 
}
