.search-dropdown-input {
    background: none;
    border: none;
    font-size: 1.25rem;
    border-radius: 0;
    color: #fff;
    border-bottom: 2px solid #fff;
    height: unset;
    padding: 0;
    padding-bottom: 0.5rem;
}

.search-dropdown-input:focus {
    color: #fff;
    background: transparent;
    border-bottom: 2px solid #fff;

}
.search-dropdown-input::placeholder { color: #ffffff98; }

.search-dropdown-box{
    position: absolute;
    right: 0;
    left: 0;
    background: #fff;
    border: 1px solid #dbdbdb;
    color: #212121;
    max-height: 30vh;
    overflow: auto;
    margin: .25rem 0;
    z-index: 1500;
    padding: 0;
}
/* .search-dropdown-box ul {
    margin: 0;
    padding: 0;
} */
.search-dropdown-box li {
    list-style: none;
    padding: 0.325rem .75rem;
    font-size: 1rem;
    cursor: pointer;
    text-transform: capitalize;
}
.search-dropdown-box li:hover{
    background-color: hsla(46, 65%, 52%, 0.12);
}
.search-dropdown-box li.active{
    font-weight: 600;
    color: #fff;
    background-color: var(--primary);
}