.gtm-input {
    background: transparent;
    border: none;
    border-bottom: 2px solid #fff;
    border-radius: 0;
    box-shadow: none;
    font-size: unset;
    padding: 0;
    height: unset;
    padding-bottom: .5rem;
    color: #fff;
}
.gtm-input:focus{
    color: #fff;
    background: transparent;
    box-shadow: none;
    border-bottom-color: var(--primary)
}

.gtm-input::placeholder,
.gtm-form-control::placeholder
{
    color: #ffffff98 !important;
}