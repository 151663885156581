textarea.gt-text-area-control:focus,
textarea.gt-text-area-control:hover{
    border-color: var(--primary);
    box-shadow: none;
    color: #031121;
}
textarea.gt-text-area-control:invalid {
    border-color: 'red'
}
textarea.gt-text-area-control.invalid {
    box-shadow: 0 0 14px 0 rgba(255, 0, 0, 0.2) !important;
    border-color: #ff0000 !important;
}
textarea.gt-text-area-control::placeholder{
    color: #9c9c9c;
    font-size: 1rem;
}

/* Material */

textarea.gtm-text-area-control {
    background: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #fff;
    color: #fff;
    padding: 0 0 .25rem 0;
    position: relative;
    box-sizing: border-box;
    resize: none;
}
textarea.gtm-text-area-control:focus{
    box-shadow: none;
    background: transparent;
    border-bottom: 2px solid #fff;
    color: #fff;
}
textarea.gtm-text-area-control::placeholder { color: #ffffff98;}
textarea.gtm-text-area-control + .info-text {
    font-size: 0.75rem;
}