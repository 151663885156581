.user-card {
    background: #fff;
    border-radius: .25rem;
}
/* .user-card-body {
    border-bottom: 1px solid #eaedf3;
} */
/* .user-card-body img[alt='profile-pic'] {
    margin-bottom: .5rem;
    max-height: 10rem;
    border-radius: 50%;
}
.user-card-body img[alt='premium']{
    height: 0.85rem;
    margin-left: .5rem; 
} */
.user-card-body .user-name{
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom:0;
    line-height: 1;
}

.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}