.file-upload{
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    height: 3em;
}
.file-upload button{
    font-weight: bold;
    color: #212121;
}
.file-upload p{
   border-right:solid 1px #eaedf3
}
.file-upload .input-file{
    position: absolute;
    width: 94%;
}
.file-upload:hover{
    border-color: var(--primary);
}