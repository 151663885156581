#user-settings {
  padding-top: 1.25rem;
}
#user-settings .title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 0;
  height: 2.8rem;
}
#user-settings .settings-list {
  list-style-type: none;
  padding: 0;
  /* margin-top: 1rem; */
  border-radius: 0.2rem;
}
#user-settings .user-info {
  background-color: #ffffff;
  /* margin-top: 3.87rem; */
  padding: 2rem;
  border-radius: 0.2rem;
}
#user-settings .general-settings {
  margin-top: 1rem;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 0.2rem;
}
#settings-expanded #save-button {
  display: "flex";
  justify-content: "flex-end";
  padding-right: 0;
}
.change-password {
  font-size: 1.3rem;
  font-weight: bold;
}
#user-settings .subtitle {
  font-size: 0.8rem;
  color: #9c9c99;
  font-weight: bold;
}
.user-settings-footer {
  padding-top: 15rem;
}

.update-password-button {
  height: 3rem;
  border-radius: 0.2rem;
  border: solid 0.09rem var(--primary);
  margin-top: 1.25rem;
  padding: 0.75rem 1.45rem;
}
.update-password-button:active {
  font-weight: bold;
}
/* profile-settings */
#profile-settings .profile-details {
  background-color: #ffffff;
  margin-top: 1rem;
  padding: 2rem;
  border-radius: 0.2rem;
}
#profile-settings .profile-form {
  margin-top: 1.5rem;
}

#profile-settings .sub-title {
  font-size: 0.75rem;
  font-weight: bold;
  color: #9c9c9c;
}
#profile-settings .delete-account-button {
  padding: 0;
  margin-top: 1.5rem;
}
#profile-settings .delete-button-title {
  margin-left: 0.25rem;
  vertical-align: middle;
  font-size: 1rem;
  font-weight: normal;
  color: #9c9c9c;
  padding-left: 0;
}
#profile-settings .settings-title {
  font-size: 1.27rem;
  font-weight: bold;
  color: #212121;
}

/* notifications-settings */
#notifications-settings {
  padding: 2rem;
  /* margin-top: 3.87rem; */
  background-color: #ffffff;
  border-radius: 0.2rem;
}
#notifications-settings .notification-settings-title {
  font-size: 1.3rem;
  font-weight: bold;
  font-stretch: normal;
  color: #212121;
}
#notifications-settings .notification-settings-description {
  font-size: 1rem;
  font-weight: normal;
  color: #9c9c9c;
  margin-bottom: 1.5rem;
}

/* Membership */
#membership-settings {
  /* margin-top: 3.87rem; */
  background-color: #ffffff;
  border-radius: 0.2rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

#membership-settings .membership-title {
  font-size: 1.27rem;
  font-weight: bold;
  text-align: center;
  color: #212121;
  margin-top: 2.2rem;
}
#membership-settings .membership-subtitle {
  font-size: 1rem;
  font-weight: normal;
  text-align: center;
  color: #9c9c9c;
}
#membership-settings .view-plans-button {
  padding: 0.625rem 1.56rem;
  border: solid 0.09rem var(--primary);
  border-radius: 0.18rem;
  margin-top: 1.43rem;
}

#membership-settings .membership-plan {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-image: linear-gradient(275deg, #d49437 100%, #d4af37);
  color: white;
}

#membership-settings .cancel-border {
  border-bottom: solid 1px #eaedf3;
}

#membership-settings .subscription-close {
  cursor: pointer;
  transition: 0.3s;
}

#membership-settings .subscription-close:hover {
  transform: scale(1.2);
  padding-right: 0.3rem;
}

/* payment-info-settings */
#payment-info-settings {
  /* margin-top: 3.87rem; */
  background-color: #ffffff;
  border-radius: 0.2rem;
  padding: 2.56rem 2rem;
}
#payment-info-settings .saved-cards {
  font-size: 1.31rem;
  font-weight: bold;
  color: #212121;
}
#payment-info-settings .cards-description {
  font-weight: normal;
  color: #9c9c9c;
}
#payment-info-settings .add-card-button {
  font-weight: normal;
  color: var(--primary);
  text-align: left;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.81rem;
  padding-bottom: 0.81rem;
  margin-top: 2.5rem;
  border-radius: 0.25rem;
  border: solid 1px #eaedf3;
  background-color: #ffffff;
  transition: 0.8s;
  overflow: hidden;
  height: 3.2rem;
}

.card-details-active {
  height: 21.2rem !important;
}

#payment-info-settings .add-card-button:focus {
  outline: var(--primary);
}

/* personal-asessment-settings */
#personal-asessment-settings {
  /* margin-top: 3.87rem; */
  background-color: #ffffff;
  border-radius: 0.2rem;
  padding: 2.56rem 2rem;
}
#personal-asessment-settings .assement-title {
  font-size: 1.31rem;
  font-weight: bold;
  color: #212121;
}
#personal-asessment-settings .assement-subtitle {
  font-size: 1rem;
  font-weight: normal;
  color: #9c9c9c;
}
#personal-asessment-settings .asessment-practitioner-list {
  margin-top: 2.5rem;
}
#personal-asessment-settings .shared-practitioner-image {
  height: 2.5rem;
  width: 2.5rem;
  object-fit: cover;
  border-radius: 1.25rem;
}
.shared-status {
  font-size: 0.75rem;
  font-weight: normal;
  color: #9c9c9c;
}
.shared-prac-category {
  text-transform: capitalize;
  font-size: 0.75rem;
  color: #9c9c99;
  font-weight: normal;
}
.shared-prac-category span:not(:last-child)::after {
  content: ", ";
}

/* order-history */
#order-history {
  /* margin-top: 3.87rem; */
  background-color: #ffffff;
  border-radius: 0.2rem;
  padding: 1.625rem 2rem;
}
#order-history .order-history-title {
  font-size: 1.31rem;
  font-weight: bold;
  color: #212121;
}
#order-history .order-history-subtitle {
  font-size: 1rem;
  font-weight: normal;
  color: #9c9c9c;
}
#order-history .order-history-list {
  margin-top: 2.5rem;
}

.plan-description {
  font-size: 0.75rem;
}

.plan-tick-icon {
  width: 2rem;
}

.price-card-skelton {
  height: 15.56rem;
  border-radius: 4px;
  box-shadow: 0 2px 22px 0 rgba(205, 205, 205, 0.51);
  border: solid 1px #eaedf3;
  background-color: #ffffff;
  cursor: pointer;
  transition: 0.3s;
}

.member-skelton {
  height: 5rem;
  border-radius: 4px;
  border: solid 1px #eaedf3;
  background-color: #ffffff;
  transition: 0.3s;
}

@media( max-width: 576px) {
  .card-details-active {
    height: 30rem !important;
  }
}
@media(min-width: 992px) {
  .save-changes { margin-top: -3.75rem;}
}
