.change-thumbnail {
    position: relative;
    height: 230px;
}
.change-thumbnail .file-change {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    transition: .2s;
    /* cursor: pointer; */
    background-color: rgba(0, 0, 0, 0.424);
}
.change-thumbnail:hover .file-change {
    opacity: 1;
}
.change-thumbnail img.thumbnail {
    height: 100%;
    width: 100%;
    object-fit: cover;
}