.react-datepicker-wrapper,
.react-datepicker__input-container
 {
    display: block !important;
}
div.react-datepicker__day--selected,
div.react-datepicker__day--keyboard-selected {
    border-radius: 50%;
    background-color: var(--primary);
}
div.react-datepicker__day:hover { border-radius: 50%}
.react-datepicker__year-select,
.react-datepicker__month-select {
    border-radius: 0.25rem;
    margin: 0 .25rem;
    cursor: pointer;
}
.react-datepicker__day:hover {
    background-color: #d4af375e !important;
}
.react-datepicker__year-select:focus,
.react-datepicker__month-select:focus{
    outline: none;
}

.gt-datepicker img[alt='datepicker'] {
    position: absolute;
    right: 0.75rem;
    top: 1rem;
    z-index: 1;
    height: 1rem;
    /* transform: translateY(-50%) */
}