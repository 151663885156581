.practitioner-license-card {
    background: #fff;
    border-radius: 0.25rem;
}
.practitioner-license-card .practitioner-license-card-header {
    border-bottom: 1px solid #eaedf3;
}
.practitioner-license-card .practitioner-license-card-header h6 {
    font-weight: bold;
    margin: 0
}
.practitioner-license-card .certificate {
    background-color: rgb(185, 148, 88, 0.21);
    border-radius: .25rem;
    color: #b99458;
}
.practitioner-license-card .certificate:not(:last-child) { margin-bottom: 1rem}
.practitioner-license-card .certificate p:first-child {
    font-weight: bold;
}
.practitioner-license-card .certificate p:nth-child(2){
    font-size: 0.75rem;
}
