.signin-banner {
    background-blend-mode: multiply;
    background-image: linear-gradient(to top, #031121, rgba(3, 17, 33, 0)),url('/images/login_banner.png');
    background-size: cover;
    background-position: top;   
    color: #fff;               
}
.signin-banner h1 {
    font-size: 2.25rem;
    font-weight: bold;
}

.oauth-container::after {
    position: absolute;
    content: '';
    border-bottom: solid 1px #dedede;
    bottom: 0;
    right: 15%;
    left: 15%;
}
.oauth-container::before {
    position: absolute;
    content: 'OR';
    bottom: 0;
    z-index: 1;
    padding: 0 .5rem;
    color: #212121;
    font-size: .75rem;
    left: 50%;
    background-color: #fff;
    transform: translate(-50%, 50%);
}


.signin-text-box h2 {
    color: #212121;
    font-weight: bold;
    /* white-space: nowrap; */
}
.signin-text-box p {
    color: #9c9c9c;
}

.error-feedback {
    display: block;
    color: #ff0000;
    font-size: 0.75rem;
    line-height: 1.5;
    margin: 4px 0;
    /* position: absolute; */
    top: 1.5em;
    animation: errorAnimate .3s;
    animation-iteration-count: 1
}

@keyframes errorAnimate {
    0%, 50% {
        transform: translateX(-5px);
    }
    20%, 75% {
        transform: translateX(5px);
    }
    100% {
        transform: translateX(0);
    }
}
.auth-footer {
    color: #212121;

}
.auth-footerlink {
    color: var(--primary);
    font-weight: 600;
}

.remember-me{
    color: #9c9c9c;
    font-size: .75rem;
}
.forgot-password {
    color: #212121;
    font-size: .75rem;
}
.forgot-password:hover {
    color: #212121;
}

@media (max-width: 768px) {
    .signin-text-box h2 {
        font-size: 1.75rem;
        white-space: nowrap;
    }
}
