.content-list-card .card-content-img{
    height: 5.937rem;
    width: 5.937rem;
    border-radius: 3px;
    min-width: 5.937rem;
    box-sizing: content-box;
    object-fit: cover;
}
.content-list-card h6{
    font-weight: 600;
   /* autoprefixer: off */
   -webkit-box-orient: vertical;
   display: block;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   overflow: hidden;
   text-overflow: ellipsis
}
.content-list-card .type-icon{
    position: relative;
    top: 1.7rem;
    left: 0.5rem;
    z-index: 80;
}