.wysiwig-editor{
    min-height: 22.75rem;
    border:1px solid #ced4da
}

.wysiwig-editor .toolbar-box{
    border-bottom:1px solid #ced4da;
    border-left:0;
    border-right:0;
    border-top:0;  
}
.wysiwig-editor:hover, 
.wysiwig-editor:hover .toolbar-box{
    border-color: var(--primary);
}
.wysiwig-editor .toolbar-box .rdw-option-active,
.toolbar-box .rdw-option-wrapper:hover ,
.toolbar-box .rdw-dropdown-wrapper:active,
.toolbar-box .rdw-dropdown-wrapper:hover {
    box-shadow:  1px 1px 0px var(--primary);
}
.toolbar-box .rdw-image-modal{
    left: -85px;
}
.toolbar-box .rdw-emoji-modal {
    left:-44px
}
.toolbar-box .rdw-embedded-modal {
    left:-10px
}
.toolbar-box .rdw-image-modal-header-label-highlighted,.rdw-embedded-modal-header-label {
    border-bottom: 2px solid var(--primary);
}
.toolbar-box .rdw-link-modal{
    height: auto;
}
.toolbar-box input:hover{
    border:1px solid var(--primary)
}

.rdw-editor-main {
    min-height: 22rem;
}
.rdw-editor-main blockquote{
    border-left: 6px solid var(--primary);
    padding-left: 19px;
}
.rdw-image-imagewrapper img{
    max-width: 100%;
}
.rdw-image-modal-size,
.rdw-embedded-modal-size
 { display: none; }

 .rdw-editor-main iframe
 {
    width: 100%;
    height: 350px;
 }

 @media (max-width: 575px) {
    .rdw-editor-main iframe {
        height: 250px;
    }
 }
